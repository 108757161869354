import {ColDef, ValueFormatterParams} from 'ag-grid-enterprise';
import {DateTimeUtils} from '../Infrastructure/DateTime/DateTimeUtils';
import {DeviationInfo} from '../Services/Deviations/DeviationInfo';
import dayjs from 'dayjs';
import i18n from 'i18next';
import {WarningIcon} from '../../components/Common/WarningIcon';
import React from 'react';
import {SENSOR_FILTER_ALARMS} from './DeviationFilterConstants';
import {CellClickedEvent} from 'ag-grid-community';

type FormatterParams<V> = ValueFormatterParams<DeviationInfo, V>;

export const DeviationCloumnDefinition: ColDef<DeviationInfo>[] = [
	{
		colId: 'checkbox',
		headerName: '',
		checkboxSelection: true,
		width: 60,
		sortable: false,
		onCellClicked: (params: CellClickedEvent<DeviationInfo, any>) => {
			params.node.setSelected(!params.node.isSelected());
			params.event.stopPropagation();
			params.event.preventDefault();
		},
	},
	{
		colId: 'deviation_type',
		headerName: 'Deviation',
		valueGetter: params => params.data.deviation_type,
		filter: 'agSetColumnFilter',
		filterParams: {
			values: ['ALARM', 'WARNING'],
		},
		cellRenderer: (params: {data: DeviationInfo}) => {
			const warningProps = {
				isAlarm: params.data.deviation_type === 'ALARM',
				isAcknowledged: params.data.is_acknowledged,
			};

			return (
				<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
					<WarningIcon size={2} event={warningProps} />
				</div>
			);
		},
		sortable: true,
		resizable: true,
		floatingFilter: true,
	},
	{
		colId: 'entry_reason',
		headerName: 'Incident',
		valueGetter: params => params.data.entry_reason,
		valueFormatter: params => i18n.t(`deviations.reason.${params.value}`),
		filter: 'agSetColumnFilter',
		filterParams: {
			valueFormatter: params => i18n.t(`deviations.reason.${params.value}`),
			values: Object.entries(SENSOR_FILTER_ALARMS).map(([_1, [_2, incidentTransKey]]) => incidentTransKey),
		},
		sortable: true,
		resizable: true,
		floatingFilter: true,
	},
	{
		colId: 'deviation_state',
		headerName: 'Status',
		valueGetter: params => params.data.deviation_state,
		filter: 'agSetColumnFilter',
		filterParams: {
			values: ['ACTIVE', 'INACTIVE'],
		},
		cellClassRules: {
			'state-alarm': params => params.value === 'ACTIVE',
			'state-alarm-deactivated': params => params.value === 'INACTIVE',
		},
		sortable: true,
		resizable: true,
		floatingFilter: true,
	},
	{
		colId: 'incidents_id',
		headerName: 'Incident ID',
		valueGetter: params => params.data.incidents_id,
		filter: 'agNumberColumnFilter',
		sortable: true,
		resizable: true,
		floatingFilter: true,
	},
	{
		colId: 'module_id',
		headerName: 'Module ID',
		valueGetter: params => params.data.module_id,
		filter: 'agTextColumnFilter',
		sortable: true,
		resizable: true,
		floatingFilter: true,
	},
	{
		colId: 'sensor_name',
		headerName: 'Sensor Name',
		valueGetter: params => params.data.sensor_name,
		filter: 'agTextColumnFilter',
		sortable: true,
		resizable: true,
		floatingFilter: true,
	},
	{
		colId: 'deviation_start',
		headerName: 'Deviation Start',
		valueGetter: params => params.data.deviation_start,
		valueFormatter: DateTimeFormatter,
		filter: 'agDateColumnFilter',
		sortable: true,
		resizable: true,
		floatingFilter: true,
		sort: 'desc',
	},
	{
		colId: 'deviation_end',
		headerName: 'Deviation End',
		valueGetter: params => params.data.deviation_end,
		valueFormatter: DateTimeFormatter,
		filter: 'agDateColumnFilter',
		sortable: true,
		resizable: true,
		floatingFilter: true,
	},
	{
		colId: 'is_acknowledged',
		headerName: 'Acknowledged',
		valueGetter: params => (params.data.is_acknowledged ? 'ACKNOWLEDGED' : 'NOT ACKNOWLEDGED'),
		filter: 'agSetColumnFilter',
		filterParams: {
			values: [true, false],
			valueFormatter: params => {
				console.log(params);
				return params.value === 'true' ? 'ACKNOWLEDGED' : 'NOT ACKNOWLEDGED';
			},
		},
		sortable: true,
		resizable: true,
		floatingFilter: true,
	},
	{
		colId: 'acknowledged_by',
		headerName: 'Acknowledged By',
		valueGetter: params => params.data.acknowledged_by,
		filter: 'agTextColumnFilter',
		sortable: true,
		resizable: true,
		floatingFilter: true,
	},
	{
		colId: 'acknowledge_comment',
		headerName: 'Acknowledged Comment',
		valueGetter: params => params.data.acknowledge_comment,
		filter: 'agTextColumnFilter',
		sortable: true,
		resizable: true,
		floatingFilter: true,
	},
	{
		colId: 'deviation_acknowledged',
		headerName: 'Deviation Acknowledged',
		valueGetter: params => params.data.deviation_acknowledged,
		valueFormatter: DateTimeFormatter,
		filter: 'agDateColumnFilter',
		sortable: true,
		resizable: true,
		floatingFilter: true,
	},
];

export function DefaultCellFormatter(v: FormatterParams<any>): string {
	if (v?.node.group && v.node.rowGroupColumn !== v.column) {
		return '';
	}

	if (!v?.value || v.value === '' || (Array.isArray(v.value) && v.value.length === 0)) {
		return i18n.t('global.noValue');
	}
	return v.value;
}

export function DateTimeFormatter(v: FormatterParams<dayjs.Dayjs | string>): string {
	if (v?.node.group && v.node.rowGroupColumn !== v.column) {
		return '';
	}

	if (dayjs.isDayjs(v?.value) || dayjs(v.value).isValid()) {
		const dateTime = dayjs(v.value);
		return DateTimeUtils.toUserString(dateTime);
	}

	return DefaultCellFormatter(v);
}
