import {LimitAlarmFormState} from '../Forms/LimitAlarmForm/LimitAlarmFormState';
import {ChannelType, EcologProXGMetaDatasForm, SensorBasicsForm} from '../AddSensor/WizardState';
import {AlarmLimitToLimitProfile} from '../Utils/AlarmLimitToLimitProfile';
import {DeviceUtils} from '../../../../common/util';

export function WizardGetSensors(
	serialNumber: string,
	sensorBasicsForm: SensorBasicsForm,
	limitAlarmFormStates: LimitAlarmFormState[],
	channelTypes: ChannelType[],
	metadataForm?: EcologProXGMetaDatasForm
) {
	let sensors = [];

	channelTypes.forEach((channelType, idx) => {
		const customerId =
			metadataForm && DeviceUtils.IsEcologProXG(serialNumber) ? metadataForm.customer_id : sensorBasicsForm.customer_id;
		const memo =
			(sensorBasicsForm.memo === '' || sensorBasicsForm.memo === null) && metadataForm ? metadataForm.memo : sensorBasicsForm.memo;

		let sensorName = sensorBasicsForm.sensor_name;
		if (!sensorName && metadataForm) {
			sensorName = metadataForm.sensor_name;
		} else if (channelTypes.length > 1) {
			sensorName = `${sensorName}_${idx + 1}${channelType.symbolValue.includes('ext') ? '(external)' : ''}`;
		}

		sensors.push({
			id: channelType.previousSensorId ?? null,
			last_run_only: sensorBasicsForm.last_run_only,
			customer_id: customerId,
			disableChangeLogging: sensorBasicsForm.disableChangeLogging,
			memo: memo,
			firmware: sensorBasicsForm.firmware,
			sensor_name: sensorName,
			unit: channelType.units.length === 1 ? channelType.units[0].id : sensorBasicsForm.unit,
			active: sensorBasicsForm.active,
			value_index: channelTypes.length > 1 ? channelType.value_index : sensorBasicsForm.selectedChannel.selectedValueIndex,
			channel_number: sensorBasicsForm.selectedChannel.selectedChannel,
			logging_interval: sensorBasicsForm.logging_interval,
			SensorLimitAlarm: AlarmLimitToLimitProfile(limitAlarmFormStates[idx]),
		});
	});

	return sensors;
}
