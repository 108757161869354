import { DbSensorLimitAlarm } from "../services/AlarmLimitService/DbSensorLimitAlarm";

type LimitBaseType = "H" | "L";

export type AlarmLimitProperty = {
	/** The type of the limit in the form of <BaseType><Index> (e.g. H1)  */
	type: string;
	limit: number;
	delay: number;
	limitProperty: keyof AlarmLimit;
	delayProperty: keyof AlarmLimit;
};

export class AlarmLimit {
	constructor(props?: Partial<DbSensorLimitAlarm>) {
		if (props) {
			this.Id = props.id;
			this.OrgId = props.org_id;
			this.UpperLimits = [props.upper_limit, props.upper_limit_2, props.upper_limit_3, props.upper_limit_4];
			this.LowerLimits = [props.lower_limit, props.lower_limit_2, props.lower_limit_3];
			this.UpperLimitDelays = [
				props.upper_limit_delay,
				props.upper_limit_delay_2,
				props.upper_limit_delay_3,
				props.upper_limit_delay_4
			];
			this.LowerLimitDelays = [props.lower_limit_delay, props.lower_limit_delay_2, props.lower_limit_delay_3];
			this.UpperLimitWarning = props.upper_limit_warning;
			this.LowerLimitWarning = props.lower_limit_warning;
			this.UpperLimitWarningDelay = props.upper_limit_warning_delay;
			this.LowerLimitWarningDelay = props.lower_limit_warning_delay;
			this.ElproCloudDefault = props.elpro_cloud_default;
			this.Name = props.name;
			this.Locked = props.locked;
			this.Active = props.active;

			this.LinkedSensors = props.sensors?.map(s => ({ Name: s.name, SerialNumber: s.serial_number })) ?? [];
		} else {
			this.UpperLimits = [0, undefined, undefined, undefined];
			this.UpperLimitDelays = [0, undefined, undefined, undefined];
			this.LowerLimits = [0, undefined, undefined];
			this.LowerLimitDelays = [0, undefined, undefined];
		}
	}

	public Id: number;
	public OrgId: number;
	public Active: boolean;

	public UpperLimits: number[];
	public LowerLimits: number[];
	public UpperLimitDelays: number[];
	public LowerLimitDelays: number[];

	public UpperLimitWarning: number;
	public LowerLimitWarning: number;
	public UpperLimitWarningDelay: number;
	public LowerLimitWarningDelay: number;
	public ElproCloudDefault: boolean;
	public Name: string;
	public Locked: boolean;
	public LinkedSensors: { Name: string; SerialNumber: string }[];
}

export function GetLimitWithDelay(alarmLimit: AlarmLimit, baseType: LimitBaseType, idx: number): Omit<AlarmLimitProperty, "type"> {
	try {
		const typeLong = `${baseType == "H" ? "Upper" : "Lower"}Limit`;
		const limitString = `${typeLong}s` as keyof AlarmLimit;
		const delayString = `${typeLong}Delays` as keyof AlarmLimit;

		if (alarmLimit[limitString][idx] == null || alarmLimit[delayString][idx] == null) {
			return undefined;
		}
		return {
			limit: alarmLimit[limitString][idx] as number,
			delay: alarmLimit[delayString][idx] as number,
			limitProperty: limitString,
			delayProperty: delayString
		};
	} catch (_) {
	}
	return undefined;
}

export function GetLimitsWithDelayByType(alarmLimit: AlarmLimit, type: LimitBaseType, order: "asc" | "desc" = "asc"): AlarmLimitProperty[] {
	const limits: AlarmLimitProperty[] = [];
	const limitCount = type == "L" ? 3 : 4;

	for (let idx = 0; idx < limitCount; idx++) {
		const limit = GetLimitWithDelay(alarmLimit, type, idx);
		if (limit) limits.push({ type: `${type}${idx + 1}`, ...limit });
	}

	if (order == "desc") {
		return limits.reverse();
	}
	return limits;
}

export function GetAllLimitsWithDelay(alarmLimit: AlarmLimit): AlarmLimitProperty[] {
	return [...GetLimitsWithDelayByType(alarmLimit, "H", "desc"), ...GetLimitsWithDelayByType(alarmLimit, "L")];
}

export function LimitProfilesAreEqual(a: AlarmLimit, b: AlarmLimit): boolean {
	if ((a && !b) || (!a && b)) return false;
	return (
		a.Id === b.Id &&
		a.OrgId === b.OrgId &&
		a.UpperLimits.every((v, i) => v === b.UpperLimits[i]) &&
		a.LowerLimits.every((v, i) => v === b.LowerLimits[i]) &&
		a.UpperLimitDelays.every((v, i) => v === b.UpperLimitDelays[i]) &&
		a.LowerLimitDelays.every((v, i) => v === b.LowerLimitDelays[i]) &&
		a.UpperLimitWarning === b.UpperLimitWarning &&
		a.LowerLimitWarning === b.LowerLimitWarning &&
		a.UpperLimitWarningDelay === b.UpperLimitWarningDelay &&
		a.LowerLimitWarningDelay === b.LowerLimitWarningDelay &&
		a.ElproCloudDefault === b.ElproCloudDefault &&
		a.Name === b.Name &&
		a.Locked === b.Locked &&
		a.Active === b.Active
	);
}
