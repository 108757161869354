import { request } from "../../../components/Infrastructure/Requests/Request";
import { AlarmLimit } from "../../types";
import { DbSensorLimitAlarm } from "../AlarmLimitService/DbSensorLimitAlarm";
import Authentication from "../../../components/Infrastructure/Authentication/Authentication";

export class LimitProfileService {
	public static async Add(limitProfile: AlarmLimit): Promise<void> {
		return LimitProfileService.addOrUpdate(limitProfile, "save");
	}

	public static async Update(limitProfile: AlarmLimit): Promise<void> {
		return LimitProfileService.addOrUpdate(limitProfile, "update");
	}

	private static async addOrUpdate(limitProfile: AlarmLimit, method: "save" | "update"): Promise<void> {
		const sensorLimitAlarm = {
			id: method == "save" ? 0 : limitProfile.Id,
			editable: !limitProfile.Locked,
			limit_alarm_name: limitProfile.Name,
			upper_limit: limitProfile.UpperLimits[0],
			upper_limit_2: limitProfile.UpperLimits[1],
			upper_limit_3: limitProfile.UpperLimits[2],
			upper_limit_4: limitProfile.UpperLimits[3],
			lower_limit: limitProfile.LowerLimits[0],
			lower_limit_2: limitProfile.LowerLimits[1],
			lower_limit_3: limitProfile.LowerLimits[2],
			upper_limit_delay: limitProfile.UpperLimitDelays[0],
			upper_limit_delay_2: limitProfile.UpperLimitDelays[1],
			upper_limit_delay_3: limitProfile.UpperLimitDelays[2],
			upper_limit_delay_4: limitProfile.UpperLimitDelays[3],
			lower_limit_delay: limitProfile.LowerLimitDelays[0],
			lower_limit_delay_2: limitProfile.LowerLimitDelays[1],
			lower_limit_delay_3: limitProfile.LowerLimitDelays[2],
			upper_limit_warning: limitProfile.UpperLimitWarning,
			lower_limit_warning: limitProfile.LowerLimitWarning,
			upper_limit_warning_delay: limitProfile.UpperLimitWarningDelay,
			lower_limit_warning_delay: limitProfile.LowerLimitWarningDelay,
			limit_alarm_creation_type: method == "save" ? 0 : limitProfile.Id,
			use_limit_alarms: true
		};

		const payload = {
			Module: null,
			Sensor: null,
			SensorLimitAlarm: sensorLimitAlarm,
			SensorIssueAlarm: null,
			PaymentInformation: null
		};

		return request({
			url: `/registry/${method}`,
			method: method == "save" ? "POST" : "PUT",
			token: Authentication.getToken(),
			data: payload
		});
	}

	public static async GetLimitProfiles(): Promise<AlarmLimit[]> {
		return LimitProfileService.getLimitProfiles();
	}

	public static async Get(profileId: number): Promise<AlarmLimit> {
		const limitProfiles = await LimitProfileService.getLimitProfiles(profileId);
		return limitProfiles[0];
	}

	private static async getLimitProfiles(profileId?: number): Promise<AlarmLimit[]> {
		let url = "/sensor_limit_alarm";
		if (profileId) url += `?id=eq.${profileId}`;

		try {
			const response = (
				await request({
					url: url,
					method: "GET",
					token: Authentication.getToken()
				})
			).data as DbSensorLimitAlarm[];
			return response.map(alarmLimit => new AlarmLimit(alarmLimit));
		} catch (e) {
			console.error(e);
			return Promise.reject(e);
		}
	}

	public static async Delete(limitProfile: AlarmLimit): Promise<void> {
		try {
			await request({
				url: `/registry/sensorlimitprofile?id=${limitProfile.Id}`,
				method: "DELETE",
				token: Authentication.getToken()
			});
		} catch (e) {
			console.error(e);
			return Promise.reject(new Error(e));
		}
	}
}
