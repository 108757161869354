import fileSaver from 'file-saver';
import request from '../../Infrastructure/Requests/Request';
import {CalibrationMeasurement} from '../../Calibration/Model/CalibrationMeasurement';
import {Calibration as Calibration} from '../../Calibration/Model/Calibration';
import {CalibrationSensor} from '../../Calibration/Model/CalibrationSensor';
import {CurrentCalibrationState} from '../../Calibration/Model/CalibrationState';
import {CalibrationOptions} from '../../Calibration/Model/CalibrationOptions';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';

class CalibrationService {
	public async tryStartCalibration(sensor_id: number, description: string, maxDuration: number): Promise<boolean> {
		const payload = {
			Action: 'start',
			Description: description,
			Sensor_Id: sensor_id,
			Timeout: maxDuration,
		};

		try {
			const response = await request({
				url: '/calibration',
				method: 'POST',
				data: payload,
				token: Authentication.getToken(),
			});

			return response.status === 200 || response.status === 201;
		} catch {
			return false;
		}
	}

	public async tryStopCalibration(sensor_id: number): Promise<boolean> {
		const payload = {
			Action: 'stop',
			Sensor_Id: sensor_id,
		};

		try {
			const response = await request({
				url: '/calibration',
				method: 'POST',
				data: payload,
				token: Authentication.getToken(),
			});

			return response.status === 200;
		} catch {
			return false;
		}
	}

	public async getCalibration(calibration_id: number): Promise<Calibration> {
		const response = await request({
			url: '/calibration/' + calibration_id,
			method: 'GET',
			token: Authentication.getToken(),
		});

		const calibrationRun = response.data;
		const calibration = new Calibration(
			calibrationRun['id'],
			calibrationRun['from'],
			calibrationRun['to'],
			calibrationRun['description'],
			calibrationRun['state']
		);

		calibration.Sensor = new CalibrationSensor(
			calibrationRun['sensorId'],
			calibrationRun['sensorName'],
			calibrationRun['sensorCustomerId'],
			calibrationRun['moduleSerialNr'],
			calibrationRun['sensorUnitId']
		);

		const measurements = [];
		calibrationRun['measurements'].forEach(calibrationMeasurement => {
			measurements.push(
				new CalibrationMeasurement(
					new Date(calibrationMeasurement['tstamp']),
					calibrationMeasurement['value'],
					calibration.Sensor.UnitID,
					calibrationMeasurement['status']
				)
			);
		});
		calibration.Measurements = measurements;
		calibration.LastMeasurement = measurements?.length ? measurements[length - 1] : undefined;

		return calibration;
	}

	public async getCalibrations(): Promise<Calibration[]> {
		const response = await request({
			url: '/calibration',
			method: 'GET',
			token: Authentication.getToken(),
		});

		const calibrations: Calibration[] = [];
		for (const calibrationRun of response.data) {
			const calibration = new Calibration(
				calibrationRun['id'],
				calibrationRun['from'],
				calibrationRun['to'],
				calibrationRun['description'],
				calibrationRun['state']
			);

			calibration.Sensor = new CalibrationSensor(
				calibrationRun['sensorId'],
				calibrationRun['sensorName'],
				calibrationRun['sensorCustomerId'],
				calibrationRun['moduleSerialNr'],
				calibrationRun['sensorUnitId']
			);

			calibration.LastMeasurement = calibrationRun['lastMeasurement']
				? new CalibrationMeasurement(
						calibrationRun['lastMeasurement']['tstamp'],
						calibrationRun['lastMeasurement']['value'],
						calibration.Sensor.UnitID,
						calibrationRun['lastMeasurement']['status']
				  )
				: undefined;

			calibrations.push(calibration);
		}

		return calibrations;
	}

	public async getCurrentCalibrationStatus(...sensor_ids: number[]): Promise<CurrentCalibrationState[]> {
		const max_sensors_per_request = 400;
		const getCalibrationStateRequests = Array.from({length: Math.ceil(sensor_ids.length / max_sensors_per_request)}, (v, i) => {
			const params = sensor_ids
				.slice(i * max_sensors_per_request, i * max_sensors_per_request + max_sensors_per_request)
				.map(id => `sensor_id=${id}`)
				.join('&');

			return request({
				url: `/calibration/state?${params}`,
				method: 'GET',
				token: Authentication.getToken(),
			});
		});

		const calibrationStateResponses = (await Promise.all(getCalibrationStateRequests)).flatMap(response => response.data);
		const result: CurrentCalibrationState[] = [];
		for (const calib of calibrationStateResponses) {
			const calibrationOptions = new CalibrationOptions(calib['startCalibrationPossible'], calib['stopCalibrationPossible']);
			result.push(new CurrentCalibrationState(calib['sensorId'], calib['state'], calibrationOptions));
		}

		return result;
	}

	public async exportCalibrationResult(calibration_id: number): Promise<void> {
		var response = await request({
			url: `/calibration/${calibration_id}/export`,
			method: 'GET',
			responseType: 'blob',
			headers: {
				Accept: 'application/json',
			},
			token: Authentication.getToken(),
		});

		var contentDisposition = response.headers['content-disposition'];
		var match = contentDisposition.match(/.*filename\s*=\s*(.+);/i);
		var filename = match[1];

		fileSaver.saveAs(response.data, filename);
	}
}

export default CalibrationService;
