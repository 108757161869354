import React, {RefObject, useEffect, useRef, useState} from 'react';
import {Access} from '../../Infrastructure/Authorization/Components';
import {AgGridReact} from 'ag-grid-react';
import {ColDef, ValueGetterFunc, ValueGetterParams} from 'ag-grid-enterprise';
import {ContentWrapper, ViewWrapper} from '../../Layout';
import {ViewHeader} from '../../Common';
import {AccessPermissions, UserRoles} from '../../Infrastructure/Authorization/Access';
import {Button, Col} from 'antd';
import {LimitProfileService} from '../../../common/services';
import {AlarmLimit} from '../../../common/types';
import {AgGridWrapper} from '../../Shared/Components';
import {CONSTANTS} from '../../Shared/Constants/Constants';
import {LockFilled, UnlockOutlined} from '@ant-design/icons';
import {NoRowsOverlay} from '../../Shared/Components/NoRowsOverlay';
import {LimitProfileGridActions} from './LimitProfileGridActions';
import {useLimitProfileActions} from '../useLimitProfileActions';
import styles from './styles.module.css';

export function LimitProfilesGrid(): React.ReactNode {
	const {addLimitProfile, viewLimitProfile} = useLimitProfileActions();
	const gridRef: RefObject<AgGridReact<AlarmLimit>> = useRef<AgGridReact<AlarmLimit>>(null);

	const [limitProfiles, setLimitProfiles] = useState<AlarmLimit[]>();

	useEffect(() => {
		LimitProfileService.GetLimitProfiles().then(setLimitProfiles);
	}, []);

	const inUseByValueGetter: ValueGetterFunc<AlarmLimit> = (params: ValueGetterParams<AlarmLimit>) => params.data.LinkedSensors.length;
	const inUseByValueCellRenderer = (props: {value: number}) => (props.value > 0 ? props.value : CONSTANTS.DEFAULT);

	// The props are CustomCellRendererProps in newer versions of AgGrid
	const lockedCellRenderer = (props: {value: boolean}) => {
		if (props.value) return <LockFilled />;
		return <UnlockOutlined />;
	};

	// Wrong type (see above) but this type has some of the properties
	const actionButtonCellRenderer = (props: {data: AlarmLimit}): React.ReactNode => {
		return <LimitProfileGridActions limitProfile={props.data} />;
	};

	const dataCol: Partial<ColDef> = {sortable: true, filter: true};
	const alignCenter: Partial<ColDef> = {
		headerClass: styles.textCenter,
		cellStyle: {
			textAlign: 'center',
		},
	};

	const columnDefs: ColDef<AlarmLimit>[] = [
		{field: 'Name', headerName: 'Name', flex: 1, ...dataCol},
		{
			headerName: 'In use by',
			valueGetter: inUseByValueGetter,
			cellRenderer: inUseByValueCellRenderer,
			width: 130,
			...alignCenter,
			...dataCol,
		},
		{
			field: 'Locked',
			headerName: 'Locked',
			cellRenderer: lockedCellRenderer,
			width: 110,
			...alignCenter,
			...dataCol,
		},
		{
			headerName: '',
			cellRenderer: actionButtonCellRenderer,
			width: 130,
			...alignCenter,
		},
	];

	return (
		<ViewWrapper>
			<ViewHeader heading={'Limit Profiles'} knowledgeHelpId={'040'}>
				<Access
					access={AccessPermissions.settings.child.limitProfiles.child.limitProfile.child.addLimitProfile}
					roles={UserRoles.default}
				>
					<Col>
						<Button id="btnAddLimitProfile" type={'primary'} size={'large'} onClick={addLimitProfile}>
							Add Limit Profile
						</Button>
					</Col>
				</Access>
			</ViewHeader>
			<ContentWrapper>
				<AgGridWrapper>
					<AgGridReact<AlarmLimit>
						rowData={limitProfiles}
						ref={gridRef}
						columnDefs={columnDefs}
						noRowsOverlayComponent={<NoRowsOverlay />}
						onRowDoubleClicked={event => viewLimitProfile(event.data)}
						statusBar={{
							statusPanels: [
								{statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
								{statusPanel: 'agFilteredRowCountComponent', align: 'left'},
								{statusPanel: 'agSelectedRowCountComponent', align: 'left'},
							],
						}}
						suppressContextMenu={true}
					/>
				</AgGridWrapper>
			</ContentWrapper>
		</ViewWrapper>
	);
}
