import request from '../Infrastructure/Requests/Request';
import Authentication from '../Infrastructure/Authentication/Authentication';
import Shared from '../Shared/Shared';
import fileSaver from 'file-saver';
import {GetSensorTiltResult} from '../../common/types/DbModel/GetSensorTiltResult';
import {SensorTiltValue} from '../../common/types/SensorTiltValue';

function measurements(params, eventData) {
	return request({
		url: '/measurements' + params,
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

async function tiltValues(id: string, from: string, to: string, eventData: any) {
	try {
		const response = await request({
			url: 'rpc/get_sensor_tilt?s_id=' + id + '&tfrom=' + from + '&tto=' + to,
			method: 'GET',
			token: Authentication.getToken(),
			eventData,
		});

		return response.data.map((r: GetSensorTiltResult) => new SensorTiltValue(r));
	} catch (e) {
		return Promise.reject(e);
	}
}

function occurrences(params, eventData) {
	return request({
		url: '/occurrences' + params,
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

function otherOccurrences(params, eventData) {
	return request({
		url: '/other_occurrences' + params,
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

function getSensorAlarms(id) {
	return request({
		url: '/sensor_alarms?sensors_id=eq.' + id,
		method: 'GET',
		token: Authentication.getToken(),
	});
}

function getSensorAlarmsFromRange(id, from, to, eventData) {
	return request({
		url: '/rpc/sensor_limits?s_id=' + id + '&ts_from=' + from + '&ts_to=' + to,
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

function getEventsForSensor(params, eventData) {
	return request({
		url: '/events_for_sensor' + params,
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

async function sensorAnalysisReport(payload, eventData) {
	const response = await request({
		url: '/report/sensorAnalyse',
		method: 'POST',
		data: payload,
		responseType: 'blob',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/pdf',
		},
		token: Authentication.getToken(),
		eventData,
	});

	saveResponseAsFile(response);
}

function getGeoDatas(id: string, from: string, to: string, eventData) {
	return request({
		url: 'rpc/get_sensor_geo_data?s_id=' + id + '&tfrom=' + from + '&tto=' + to,
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

async function exportData(payload, eventData) {
	const response = await request({
		url: '/export/sensorData',
		method: 'POST',
		data: payload,
		responseType: 'blob',
		headers: {
			Authorization: 'Bearer ' + Authentication.getToken(),
			'Content-Type': 'application/json',
			Accept: '*/*',
		},
		token: Authentication.getToken(),
		eventData,
	});

	saveResponseAsFile(response);
}

async function exportGeoData(payload, eventData) {
	const response = await request({
		url: '/export/sensorGeoData',
		method: 'POST',
		data: payload,
		responseType: 'blob',
		headers: {
			Authorization: 'Bearer ' + Authentication.getToken(),
			'Content-Type': 'application/json',
			Accept: '*/*',
		},
		token: Authentication.getToken(),
		eventData,
	});

	saveResponseAsFile(response);
}

function saveResponseAsFile(response: any) {
	var contentDisposition = response.headers['content-disposition'];
	var match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
	var filename = match[1];

	fileSaver.saveAs(response.data, filename);
}

const SensorAnalysisService = {
	occurrences,
	otherOccurrences,
	measurements,
	tiltValues,
	getSensorAlarms,
	getEventsForSensor,
	sensorAnalysisReport,
	getSensorAlarmsFromRange,
	getGeoDatas,
	exportData,
	exportGeoData,
};

export default SensorAnalysisService;
