import {useNavigate} from 'react-router-dom-v5-compat';
import {AlarmLimit} from '../../common/types';
import {ConfirmWarn} from '../Common/Alerts';
import {LimitProfileService} from '../../common/services';

export function useLimitProfileActions() {
	const navigate = useNavigate();

	const addLimitProfile = () => {
		navigate(`/limitProfiles/new`, {state: {editing: true}});
	};

	const copyLimitProfile = (limitProfile: AlarmLimit) => {
		navigate(`/limitProfiles/new`, {state: {limitProfile: limitProfile, editing: true}});
	};

	const editLimitProfile = (limitProfile: AlarmLimit) => {
		navigate(`/limitProfiles/${limitProfile.Id}`, {state: {limitProfile: limitProfile, editing: true}});
	};

	const deleteLimitProfile = (limitProfile: AlarmLimit) => {
		let message = `<p>Are you sure you want to delete the limit profile "${limitProfile.Name}"? This cannot be undone.</p>`;
		message += '<p>';
		if (limitProfile.LinkedSensors.length > 0) {
			message += `This limit profile is linked to ${limitProfile.LinkedSensors.length} sensor(s).`;
			message += '<ul style="list-style-type: none">';
			message += limitProfile.LinkedSensors.map(sensor => `<li>${sensor.Name} (${sensor.SerialNumber})</li>`).join(', ');
			message += '</ul>';
		} else {
			message += `This limit profile is not linked to any sensor.`;
		}
		message += '</p>';

		ConfirmWarn.fire({
			title: `Delete "${limitProfile.Name}"`,
			html: message,
			confirmButtonText: 'Delete',
			icon: 'warning',
		}).then(result => {
			if (result.isConfirmed) {
				LimitProfileService.Delete(limitProfile)
					.then(() => navigate('/limitProfiles'))
					.catch(error => console.error(error));
			}
		});
	};

	const viewAllLimitProfiles = () => {
		navigate('/limitProfiles');
	};

	const viewLimitProfile = (limitProfile: AlarmLimit) => {
		navigate(`/limitProfiles/${limitProfile.Id}`, {state: {limitProfile: limitProfile}});
	};

	return {
		addLimitProfile,
		copyLimitProfile,
		deleteLimitProfile,
		editLimitProfile,
		viewAllLimitProfiles,
		viewLimitProfile,
	};
}
