import {Access} from '../../Infrastructure/Authorization/Components';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';
import React, {useState} from 'react';
import {Button, Popover, TableProps} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import Shared from '../../Shared/Shared';
import {AntStyledTable} from '../../Shared/Styles/AntStyledTable';
import {RunInfo} from '../../../common/types';
import {ColumnsType} from 'antd/es/table';
import {formatDayJsInUserTz} from '../../Infrastructure/DateTime/DateTimeUtils';
import {CONSTANTS} from '../../Shared/Constants/Constants';

export interface MultiRunDropdownProps {
	runInfos: RunInfo[];
	selectedRunId: number;
	onSelect: (run: RunInfo) => void;
}

export function MultiRunDropdown(props: Readonly<MultiRunDropdownProps>) {
	const [selectedRun, setSelectedRun] = useState(props.runInfos?.find(run => run.SensorId == props.selectedRunId));
	const selectedRunIndex = props.runInfos?.findIndex(run => run.SensorId == selectedRun.SensorId);
	if (!props.runInfos) return null;

	const onChange = (runInfo: RunInfo) => {
		setSelectedRun(runInfo);
		props.onSelect(runInfo);
	};

	const rowSelection: TableProps<RunInfo>['rowSelection'] = {
		type: 'radio',
		onChange: (_: React.Key[], selectedRows: RunInfo[]) => onChange(selectedRows[0]),
		selectedRowKeys: [selectedRun.SensorId],
	};

	const content = () => {
		return (
			<AntStyledTable<RunInfo>
				columns={columns}
				dataSource={props.runInfos}
				locale={{emptyText: 'No data'}}
				onRow={(record: RunInfo, _) => {
					return {
						onClick: _ => {
							onChange(record);
						},
					};
				}}
				pagination={{
					defaultPageSize: 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30'],
					position: ['topLeft'],
				}}
				rowKey="SensorId"
				rowSelection={rowSelection}
				scroll={Shared.setTableScroll()}
				size="small"
			/>
		);
	};

	const columns: ColumnsType<RunInfo> = [
		{
			title: 'ID',
			dataIndex: 'SensorId',
			width: '10%',
		},
		{
			title: 'Started',
			dataIndex: 'StartDate',
			width: '40%',
			render: value => formatDayJsInUserTz(value),
		},
		{
			title: 'Ended',
			dataIndex: 'EndDate',
			width: '40%',
			render: value => formatDayJsInUserTz(value) ?? CONSTANTS.DEFAULT,
		},
	];

	return (
		<>
			<Access
				access={accessPermissions.devicesview.child.dashboard.child.sensorAnalysis.child.selectPreviousSensorRuns}
				roles={userRoles.default}
			>
				<Popover content={content} placement="bottomLeft" arrow={false}>
					<Button>
						Current selected Sensor Run: {selectedRunIndex + 1} (of Total: {props.runInfos.length}) <DownOutlined />
					</Button>
				</Popover>
			</Access>
			<span style={{padding: '5px'}}>
				Period: {formatDayJsInUserTz(selectedRun.StartDate)} - {formatDayJsInUserTz(selectedRun.EndDate) ?? ''}
			</span>
		</>
	);
}
