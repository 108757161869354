import {DeviationStatus} from '../../../components/Common/Types/DeviationStatus';
import request from '../../../components/Infrastructure/Requests/Request';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
import {DeviationInfo} from '../../../components/Services/Deviations/DeviationInfo';
import {IServerSideGetRowsRequest} from 'ag-grid-enterprise';
import {getFilterParams, getSortParms} from '../Helpers/AgGridReactSSRQueryBuilder';

export class DeviationService {
	public static async GetDeviationStatus(): Promise<DeviationStatus[]> {
		let deviationStatus = [];
		try {
			deviationStatus = (
				await request({
					url: '/deviations_status',
					method: 'GET',
					token: Authentication.getToken(),
				})
			).data;
		} catch (e) {
			console.error(e);
		}

		return deviationStatus.map(d => new DeviationStatus(d));
	}

	public static async GetDeviations(params: IServerSideGetRowsRequest): Promise<{total: number; deviations: DeviationInfo[]}> {
		let sortParms = getSortParms(params);
		let filterParams = getFilterParams(params);

		const res = await request({
			url: `/deviations_info?limit=${params.endRow - params.startRow}&offset=${params.startRow}${sortParms}${filterParams}`,
			method: 'GET',
			token: Authentication.getToken(),
			headers: {Prefer: 'count=exact'},
		});

		return {
			total: res.headers['content-range'].split('/')[1],
			deviations: res.data,
		};
	}
}
