import Swal from 'sweetalert2';

export const BaseAlert = Swal.mixin({
	confirmButtonColor: '#3085d6',
});

export const ErrorAlert = BaseAlert.mixin({icon: 'error'});
export const Success = BaseAlert.mixin({icon: 'success'});

export const ConfirmWarn = Swal.mixin({
	icon: 'warning',
	confirmButtonColor: '#d33',
	cancelButtonColor: '#3085d6',
	showCancelButton: true,
});
