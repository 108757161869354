import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

export type Row = {key: string; value: ReactNode};

export function FormSummary(props: Readonly<{title: string; rows: Row[]}>): ReactNode {
	const [t] = useTranslation();
	return (
		<>
			<tr style={{backgroundColor: '#b1b3b3'}} key={props.title + 'Heading'}>
				<td colSpan={2}>{t(props.title).toString()}</td>
			</tr>
			{props.rows.map(row => {
				return (
					<tr key={row.key}>
						<td>{t(row.key).toString()}</td>
						<td>{row.value}</td>
					</tr>
				);
			})}
		</>
	);
}
