import React, {ReactElement} from 'react';
import styled from 'styled-components';
import {Tooltip} from 'antd';

const button = styled.span`
	margin: auto 4px;
	cursor: pointer;
	opacity: 1;
`;
const buttonLocked = styled.span`
	margin: auto 4px;
	cursor: not-allowed;
	color: dimgray;
	opacity: 0.7;
`;

export function ActionButton({onClick, locked, actionType, id}: ButtonProps): ReactElement {
	const className = 'elpro-Button-M elpro-Data-Grid-' + actionType;
	const Button = locked ? buttonLocked : button;
	return (
		<Tooltip title={locked ? 'You cannot ' + actionType.toLowerCase() + ' a locked item' : actionType}>
			<Button className={className} onClick={locked ? undefined : onClick} id={id} alt />
		</Tooltip>
	);
}

type ButtonProps = {
	id: string;
	onClick: () => void;
	locked: boolean;
	actionType: ActionButtonAction;
};

export enum ActionButtonAction {
	Copy = 'Copy',
	Edit = 'Edit',
	Delete = 'Delete',
}
