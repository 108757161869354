import React, {useEffect, useState, useContext, CSSProperties} from 'react';
import {Badge, Col, ColProps} from 'antd';
import pubsub from 'pubsub-js';
import DeviationsService from './DeviationsService';
import {Link} from 'react-router-dom';
import CreateSupportTicket from '../Shared/Components/CreateSupportTicket';
import EnableHubspotChat from '../Shared/Components/EnableHubspotChat';
import {layoutPermissions} from '../Infrastructure/Authorization/Access';
import {AccessContext} from '../Infrastructure/Authorization/Context/AccessContext';
import {DEVICE_EVENT} from '../Shared/Constants/Events';

export const DeviationsHeader = () => {
	const userAccess = useContext(AccessContext);

	const [alarmCount, setAlarmCount] = useState(undefined);
	const [warningCount, setWarningCount] = useState(undefined);
	const [alarmIcon, setAlarmIcon] = useState('');
	const [warningIcon, setWarningIcon] = useState('');
	const [showSupportTicket, setShowSupportTicket] = useState(true);
	const [subscriptions, setSubscriptions] = useState<PubSubJS.Token[]>([]);

	useEffect(() => {
		getLayoutPermissions(layoutPermissions, userAccess);

		if (!alarmCount || !warningCount) {
			handleDisplay();
		}

		subscriptions.push(pubsub.subscribe(DEVICE_EVENT.DEVIATION_OCCURRED, handleDisplay));
		subscriptions.push(pubsub.subscribe(DEVICE_EVENT.DEVIATION_ACKNOWLEDGED, handleDisplay));
		subscriptions.push(pubsub.subscribe(DEVICE_EVENT.ALL_DEVIATION_ACKNOWLEDGED, handleDisplay));

		return () => subscriptions.forEach(token => pubsub.unsubscribe(token));
	}, []);

	const getLayoutPermissions = (layoutPermissions, access) => {
		if (access.user && userAccess.user.access_settings.layout) {
			const {header} = access.user.access_settings.layout;
			setShowSupportTicket(header[layoutPermissions.header.create_support_ticket.key]);
		}
	};

	const handleDisplay = () => {
		let gets = [DeviationsService.deviations_notaknowledgedalarms(), DeviationsService.deviations_notaknowledgedwarnings()];

		Promise.all(gets).then(responses => {
			try {
				const inactiveAlarmsNotAcknowledgedCount = responses[0].data[0].count;
				const inactiveWarningsNotAcknowledgedCount = responses[1].data[0].count;
				setAlarmCount(inactiveAlarmsNotAcknowledgedCount);
				setAlarmIcon(inactiveAlarmsNotAcknowledgedCount ? 'fs2 elpro-AlarmActive' : 'fs2 elpro-AlarmAcknowledged');

				setWarningCount(inactiveWarningsNotAcknowledgedCount);
				setWarningIcon(inactiveWarningsNotAcknowledgedCount ? 'fs2 elpro-WarningActive' : 'fs2 elpro-WarningAcknowledged');
			} catch (e) {
				console.error(e);
			}
		});
	};

	function getBadgeWidth(): number {
		const values = [alarmCount, warningCount].map(v => Math.floor(Math.log10(v)) + 1);
		return Math.max(...values);
	}

	const supportColProps: ColProps = {
		xs: 0,
		sm: 4,
		md: 4,
		lg: 3,
		xl: 3,
		xxl: 2,
	};

	return (
		<>
			{showSupportTicket && (
				<>
					<Col {...supportColProps}>
						<CreateSupportTicket />
					</Col>
					<Col {...supportColProps}>
						<EnableHubspotChat />
					</Col>
				</>
			)}
			{alarmCount !== undefined && warningCount !== undefined && (
				<>
					<DeviationBadge type={'ALARM'} count={alarmCount} className={alarmIcon} minWidth={getBadgeWidth()} />
					<DeviationBadge type={'WARNING'} count={warningCount} className={warningIcon} minWidth={getBadgeWidth()} />
				</>
			)}
		</>
	);
};

function DeviationBadge(props: {
	readonly type: string;
	readonly count: number;
	readonly className: string;
	readonly minWidth?: number;
}): React.JSX.Element {
	const minWidth = props.minWidth ?? 0;

	const badgeStyle: CSSProperties = {
		fontSize: '.8em',
		// When setting a mind width on minWith == 1, the circle becomes an ugly ellipsis
		...(minWidth > 1 ? {minWidth: `${1.1 * minWidth}em`} : {}),
	};
	const colStyle: CSSProperties = {
		padding: '0 .5rem',
	};

	return (
		<Col style={colStyle}>
			<Link to={`/deviations?type=${props.type}`}>
				<Badge count={props.count} style={badgeStyle} overflowCount={1000}>
					<span className={props.className}>
						<span className="path1" />
						<span className="path2" />
						<span className="path3" />
						<span className="path4" />
					</span>
				</Badge>
			</Link>
		</Col>
	);
}

export default DeviationsHeader;
