import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import SensorAnalysisService from './SensorAnalysisService';
import Statistics from '../Services/Extensions/Statistics';
import LiveLinkService from '../LiveLink/LiveLinkService';
import {Input, Modal as AntModal} from 'antd';
import SensorService from '../Dashboard/Sensor/SensorService';
import SensorAnalysisStatistics from './SensorAnalysisStatistics';
import ChartComponent from './Chart/ChartComponent';
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from 'react-accessible-accordion';
import DateTimeUtils from '../Infrastructure/DateTime/DateTimeUtils';
import AccordionFilter from '../Common/Filter/AccordionFilter';
import utils from '../Development/DevelopmentUtils';
import axios from 'axios';
import fileSaver from 'file-saver';
import pubsub from 'pubsub-js';
import {HotKeys} from 'react-hotkeys';
import {Trans} from 'react-i18next';
import AuditTrailEventTypes from '../AuditTrail/AuditTrailEventTypes';
import moment from 'moment';
import Close from '../Shared/Components/Close';
import MapComponent from './Map/MapComponent';
import {AntStyledTable} from '../Shared/Styles/AntStyledTable';
import {accessPermissions, layoutPermissions, userRoles} from '../Infrastructure/Authorization/Access';
import {SensorMetaData} from './Components';
import {RequestLogger} from '../Infrastructure/Requests/Logger/';
import {MultiRunDropdown} from './Run/MultiRunDropdown';
import SerialNumberInfo from '../Shared/SerialNumberInfo';
import {MODULE_FAMILIES} from '../Shared/Constants/Module';
import {
	LOGGER_STATUS_EVENTS,
	OCCURRENCES,
	OTHER_OCCURRENCES,
	REPLACE_SENSOR_EVENTS,
	SENSOR_ISSUE_ALARMS,
	SENSOR_LIMIT_ALARMS,
} from '../Shared/Constants/Chart';
import AlarmStateComponent from './Run/AlarmStateComponent';
import {MultiContext} from '../Infrastructure/Authorization/Context/MultiContext';
import {Access} from '../Infrastructure/Authorization/Components';
import {ReportComponent} from './Run/ReportComponent';
import Authentication from '../Infrastructure/Authentication/Authentication';
import {PredictionResultView} from '../Predictive/PredictionResultView';
import {TiltGraph} from '../Tilt/TiltGraph';
import {Sensor} from '../Common/Types/Sensor';
import {PredictiveTrans} from '../Wizard';
import {PredictionService} from '../../common/services';
import {EventDetailList} from '../Event/EventDetailList';
import {EventDetail, RunInfo} from '../../common/types';

const keys = {debugReport: 'alt+r'};
const handlers = {debugReport: _ => pubsub.publish('debugReport')};
const statisticsNotAvailableHeaderStyle = {
	backgroundColor: '#b1b3b3',
	color: '#000000',
	padding: '12px',
	width: '100%',
	textAlign: 'left',
	border: 'none',
};

let isRunSet = false;
let callGetData = false;
let selidx = 0;

class SensorAnalysis extends Component {
	static contextType = MultiContext;
	declare context: React.ContextType<typeof MultiContext>;

	columns = [
		{
			title: 'Incident #',
			dataIndex: 'incidents_id',
			width: '10%',
		},
		{
			title: 'Date/Time',
			dataIndex: 'date_occurred',
			width: '15%',
			render: date => <span>{DateTimeUtils.getDateTimeInUserTZ(date)}</span>,
		},
		{
			title: 'Event',
			dataIndex: 'type',
			render: type => (
				<span>
					<Trans i18nKey={this.getTransKey(type)} />
				</span>
			),
		},
	];

	constructor(props) {
		super(props);

		this.chartComponent = React.createRef();
		this.tiltComponent = React.createRef();
		this.mapComponent = React.createRef();
		this.liveLinkInputRef = React.createRef();
		const last7Days = DateTimeUtils.getXDaysBeforeDay(7, undefined, true);

		const idAsInt = parseInt(props.match.params.id); //payload for report uses number
		this.state = {
			predictive: {
				enabled: false,
				sensorPredictiveInfo: null,
			},
			tilt: {
				enabled: false,
				sensorTiltValues: null,
			},
			showLastRunOnly: props.location.state && props.location.state.checkLastRunOnly,
			alarmStatus: false,
			redrawChart: true,
			id: idAsInt,
			moduleFamilyType: undefined,
			sensor: {
				name: undefined,
			},
			statistics: {
				statisticResult: {},
				resultZones: [{}, {}, {}, {}, {}, {}, {}],
			},
			events: [],
			dateFrom: last7Days.start.toDate(),
			dateTo: last7Days.end.toDate(),
			mapDateFrom: last7Days.start.toDate(),
			mapDateTo: last7Days.end.toDate(),
			debugReport: false,
			expandedKeys: [],
			accordionSensorStatistics: {
				title: '',
				disabled: false,
				last_modification_date: '',
			},
			renderChart: true,
			loadingReport: false,
			loadingContent: false,
			multiRunInfo: undefined,
			multiRunSensor: {
				initialLoad: true,
				multiRunData: [],
				isMultiRunSensor: false,
			},
			userTZOffset: DateTimeUtils.getCurrentUserTZOffset(),
			showCloseBtn: true,
			filterActive: false,
		};
	}

	componentDidMount() {
		this.getLayoutPermissions(this.context);
		this.getData();
		this.debugReportToken = pubsub.subscribe('debugReport', () => this.setState({debugReport: true}));
	}

	componentWillUnmount() {
		pubsub.unsubscribe(this.debugReportToken);
		isRunSet = false;
		callGetData = false;
	}

	getLayoutPermissions = context => {
		if (context.AccessContext.user && context.AccessContext.user.access_settings.layout) {
			const {analysis} = context.AccessContext.user.access_settings.layout;
			this.setState({showCloseBtn: analysis[layoutPermissions.analysis.close_btn.key]});
		}
	};

	getData = () => {
		if (!(!isNaN(Date.parse(this.state.dateFrom)) && !isNaN(Date.parse(this.state.dateTo)))) {
			return;
		}
		const dateFrom = DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.dateFrom, DateTimeUtils.getCurrentUserTZOffset());
		const dateTo = DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.dateTo, DateTimeUtils.getCurrentUserTZOffset());

		let gets = [
			SensorService.sensor(this.state.id, RequestLogger.createLogData('sensor-analysis', 'load-sensor-data', 'onLoad')),
			SensorAnalysisService.measurements(
				'?sensors_id=eq.' +
					this.state.id +
					'&tstamp=gte.' +
					dateFrom +
					'&tstamp=lte.' +
					dateTo +
					'&order=tstamp' +
					'&select=tstamp,value,deferred,bypassed,status,error,calibration',
				RequestLogger.createLogData('sensor-analysis', 'load-measurements', 'onLoad')
			),
			SensorAnalysisService.getSensorAlarmsFromRange(
				this.state.id,
				dateFrom,
				dateTo,
				RequestLogger.createLogData('sensor-analysis', 'load-sensor-alarm-from-range', 'onLoad')
			),
			SensorAnalysisService.occurrences(
				'?sensors_id=eq.' +
					this.state.id +
					'&tstamp=gte.' +
					dateFrom +
					'&tstamp=lte.' +
					dateTo +
					'&order=tstamp' +
					'&select=tstamp,type_name,prev_state,new_state',
				RequestLogger.createLogData('sensor-analysis', 'load-occurrences', 'onLoad')
			),
			SensorAnalysisService.otherOccurrences(
				'?sensors_id=eq.' +
					this.state.id +
					'&tstamp=gte.' +
					dateFrom +
					'&tstamp=lte.' +
					dateTo +
					'&order=tstamp' +
					'&select=tstamp,type_name,metadata',
				RequestLogger.createLogData('sensor-analysis', 'load-other-occurrences', 'onLoad')
			),
		];

		Promise.all(gets).then(responses => {
			let sensor = responses[0].data;
			let measurements = responses[1].data;
			let limitAlarms = responses[2].data;
			let occurrences = responses[3].data;
			let otherOccurrences = responses[4].data;

			const formatMeasurements = measurements.map(m => {
				const tstampString = moment.parseZone(m.tstamp).utc().toString();
				m.tstamp = DateTimeUtils.utcOffset_date_dep(tstampString).format('YYYY-MM-DDTHH:mm:ss') + '+00:00';
				m.value = m.status ? null : m.value;

				/*moment(tstampString).utcOffset(userOffset).format('YYYY-MM-DDTHH:mm:ss') + '+00:00';*/
				return m;
			});

			const formatLimitAlarms = limitAlarms.map(l => {
				const tstampStringFrom = moment.parseZone(l.valid_from).utc().toString();
				l.valid_from = DateTimeUtils.utcOffset_date_dep(tstampStringFrom).format('YYYY-MM-DDTHH:mm:ss') + '+00:00';
				//moment(tstampStringFrom).utcOffset(userOffset).format('YYYY-MM-DDTHH:mm:ss') + '+00:00';

				const tstampStringUntil = moment.parseZone(l.valid_until).utc().toString();
				l.valid_until = DateTimeUtils.utcOffset_date_dep(tstampStringUntil).format('YYYY-MM-DDTHH:mm:ss') + '+00:00';
				//moment(tstampStringUntil).utcOffset(userOffset).format('YYYY-MM-DDTHH:mm:ss') + '+00:00';

				return l;
			});

			const filteredOccurrences = this.filterTransitOccurrences(
				occurrences.map(m => {
					const tstampString = moment.parseZone(m.tstamp).utc().toString();
					m.tstamp = DateTimeUtils.utcOffset_date_dep(tstampString).format('YYYY-MM-DDTHH:mm') + '+00:00';
					//moment(tstampString).utcOffset(userOffset).format('YYYY-MM-DDTHH:mm:ss') + '+00:00';
					return m;
				})
			);

			const filteredOtherOccurrences = this.filterOtherOccurrences(
				otherOccurrences.map(m => {
					const tstampString = moment.parseZone(m.tstamp).utc().toString();
					m.tstamp = DateTimeUtils.utcOffset_date_dep(tstampString).format('YYYY-MM-DDTHH:mm') + '+00:00';
					return m;
				})
			);

			if (this.state.moduleFamilyType === undefined) {
				const moduleFamilyType = SerialNumberInfo.findFamilyType(sensor.serial_number);
				this.setState({
					moduleFamilyType: moduleFamilyType,
				});
			}

			const alarmStatus = this.getAlarmStatus(sensor.latch_active, sensor.latched_status);

			this.setState(
				{
					alarmStatus: alarmStatus,
					loadingContent: true,
					sensor: sensor,
					measurements: formatMeasurements,
					sensorErrors: this.getSensorErrors(formatMeasurements),
					sensorCalibrations: this.getSensorCalibrations(formatMeasurements),
					occurrences: filteredOccurrences,
					otherOccurrences: filteredOtherOccurrences,
					limitAlarms: formatLimitAlarms,
					accordionSensorStatistics: this.setAccordionStatisticsTitle(limitAlarms),
					limitAlarmDetails: formatLimitAlarms.length ? formatLimitAlarms[formatLimitAlarms.length - 1].details : [], // get always the last limit details for statistics
				},
				this.getOtherData
			);
		});

		if (this.context.AuthContext.Organization.Config.global.tiltEnabled && this.state.moduleFamilyType === MODULE_FAMILIES.LIBERO_G) {
			SensorAnalysisService.tiltValues(
				this.state.id,
				dateFrom,
				dateTo,
				RequestLogger.createLogData('sensor-analysis', 'load-tilt-values', 'onLoad')
			)
				.then(t => this.setState(prevState => ({...prevState, tilt: {enabled: !!t, sensorTiltValues: t}})))
				.catch();
		}
		if (this.context.AuthContext?.Organization.Config.global.predictiveEnabled) {
			PredictionService.GetSensorPredictiveInformation(this.context.AuthContext.Organization.Uuid, this.state.id)
				.then(r =>
					this.setState(prevState => ({
						...prevState,
						predictive: {
							enabled: !!r,
							sensorPredictiveInfo: r,
						},
					}))
				)
				.catch();
		}
	};

	getSensorCalibrations(measurements) {
		const calibrationRuns = [];

		measurements.forEach((measurement, idx) => {
			if (measurement.calibration && (!measurements[idx - 1] || !measurements[idx - 1].calibration)) {
				const endPoint = this.getCalibrationRunEndPoint(measurements, idx);

				calibrationRuns.push({
					start: moment.parseZone(measurement.tstamp).utc(),
					end: moment.parseZone(measurements[endPoint].tstamp).utc(),
				});
			}
		});

		return calibrationRuns;
	}

	getCalibrationRunEndPoint(measurements, startPoint) {
		const endPoint = measurements.findIndex((measurement, idx) => idx > startPoint && !measurement.calibration);
		return endPoint >= 0 ? endPoint : measurements.length - 1;
	}

	getSensorErrors(measurements) {
		const sensorErrors = [];

		measurements.forEach((measurement, idx) => {
			if (measurement.status && (!measurements[idx - 1] || measurements[idx - 1].error !== measurement.error)) {
				const endPoint = this.getErrorRangeEndPoint(measurements, idx);

				sensorErrors.push({
					start: moment.parseZone(idx < 1 ? measurement.tstamp : measurements[idx - 1].tstamp).utc(),
					end: moment.parseZone(measurements[endPoint].tstamp).utc(),
					status: measurement.status,
					type: measurement.error,
				});
			}
		});

		return sensorErrors;
	}

	getErrorRangeEndPoint(measurements, startPoint) {
		const endPoint = measurements.findIndex(
			(measurement, idx) => idx > startPoint && measurements[startPoint].error !== measurement.error
		);
		return endPoint >= 0 ? endPoint : measurements.length - 1;
	}

	getAlarmStatus(latchActive, latchedStatus) {
		return !!(latchActive && latchedStatus);
	}

	filterTransitOccurrences(occurrences) {
		return occurrences.filter(
			e =>
				(OCCURRENCES.REQUIRED_TYPES[0] === e.type_name &&
					LOGGER_STATUS_EVENTS.REQUIRED_STATUSES.some(element => element === e.new_state)) ||
				e.type_name === OCCURRENCES.REQUIRED_TYPES[1]
		);
	}

	filterOtherOccurrences(otherOccurrences) {
		return otherOccurrences.filter(e => OTHER_OCCURRENCES.REQUIRED_TYPES.some(element => element === e.type_name));
	}

	setAccordionStatisticsTitle = _ => {
		return {title: 'Statistics'};
	};

	reportLocalhost = e => {
		let chartSVG = this.chartComponent.current.getChart();
		const mapSVG = this.mapComponent.current ? this.mapComponent.current.getMap() : null;

		let payload = {
			sensor: {
				id: this.state.id,
				name: this.state.sensor.name,
				serial_number: this.state.sensor.serial_number,
				alarmStatus: this.state.alarmStatus,
			},
			timeFilter: {
				from: this.state.dateFrom,
				to: this.state.dateTo,
			},
			svgImage: chartSVG,
			svgMapImage: mapSVG,
		};

		axios({
			method: 'post',
			url: 'http://localhost:53404/report/sensorAnalyse',
			data: payload,
			headers: {
				Authorization: 'Bearer ' + Authentication.getToken(),
				'Content-Type': 'application/json',
				Accept: 'application/pdf',
			},
		})
			.then(function (response) {
				const file = utils.b64toFile(response.data, 'Report.pdf', 'application/pdf');
				fileSaver.saveAs(file, 'report.pdf');
			})
			.catch(function (error) {
				console.error(error);
			});

		e.preventDefault();
	};

	report = async e => {
		this.setState({loadingReport: true});

		const chartSVG = this.chartComponent.current ? this.chartComponent.current.getChart() : null;
		const tiltSVG = this.tiltComponent.current ? this.tiltComponent.current.chart.getChartHTML() : null;
		const mapSVG = this.mapComponent.current ? this.mapComponent.current.getMap() : null;

		let currMRStxt = '';
		if (this.state.multiRunSensor && this.state.multiRunSensor.isMultiRunSensor) {
			const mrsIdx = this.state.multiRunSensor.multiRunData.findIndex(mrd => mrd.sensors_id === this.state.id);
			const mrs = this.state.multiRunSensor.multiRunData[mrsIdx];

			currMRStxt =
				mrsIdx +
				'.Run, ' +
				DateTimeUtils.setDateTimeWithOffset_date_dep(mrs.ts_start) +
				(mrs.ts_end ? ' - ' + DateTimeUtils.setDateTimeWithOffset_date_dep(mrs.ts_end) : ' - ');
		}

		let payload = {
			sensor: {
				id: this.state.id,
				name: this.state.sensor.name,
				serial_number: this.state.sensor.serial_number,
				alarmStatus: this.state.alarmStatus,
			},
			timeFilter: {
				from: DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.dateFrom, DateTimeUtils.getCurrentUserTZOffset()),
				to: DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.dateTo, DateTimeUtils.getCurrentUserTZOffset()),
			},
			svgImage: chartSVG,
			svgMapImage: mapSVG,
			runInfo: currMRStxt,
			...(this.context.AuthContext.Organization.Config.global.tiltEnabled && {svgTiltImage: tiltSVG}),
		};

		try {
			await SensorAnalysisService.sensorAnalysisReport(
				payload,
				RequestLogger.createLogData('sensor-analysis', 'create-report', 'onClick')
			);
		} catch (error) {
			console.log(error);
		} finally {
			this.setState({loadingReport: false});
		}

		e.preventDefault();
	};

	liveLink = _ => {
		LiveLinkService.getLiveLink({sensor_id: this.state.id}, RequestLogger.createLogData('sensor-analysis', 'getLiveLink', 'onLoad'))
			.then(response => {
				const token = response.data;

				const lnk = window.location.hostname + '/liveLink/' + token;

				this.setState({liveLinkOpen: true, liveLinkTxt: lnk});
			})
			.finally(() => {
				//this.setRun(true);
			});
	};

	exportData = async (exportType, withGeoData) => {
		this.setState({loadingReport: true});

		let payload = this.GetSensorPayload(exportType);

		try {
			await SensorAnalysisService.exportData(
				payload,
				RequestLogger.createLogData('sensor-exportdata', 'export-sensordata', 'onClick')
			);
		} catch (error) {
			console.log(error);
		} finally {
			this.setState({loadingReport: false});
		}

		if (withGeoData) {
			this.setState({loadingReport: true});

			try {
				await SensorAnalysisService.exportGeoData(
					payload,
					RequestLogger.createLogData('sensor-exportdata', 'export-sensordata', 'onClick')
				);
			} catch (error) {
				console.log(error);
			} finally {
				this.setState({loadingReport: false});
			}
		}
	};

	GetSensorPayload(filesuffix) {
		return {
			SensorId: parseInt(this.state.id),
			FileSuffix: parseInt(filesuffix),
			TimeFilter: {
				FromUtc: DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.dateFrom, DateTimeUtils.getCurrentUserTZOffset()),
				ToUtc: DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.dateTo, DateTimeUtils.getCurrentUserTZOffset()),
			},
		};
	}

	getOtherData = () => {
		this.getMultiRunSensorData(this.state.sensor);
		this.getStatistics();
		this.getEventsForSensor();
		this.setMapDate();
	};

	getMultiRunSensorData = sensor => {
		if (sensor.multirun && this.state.multiRunSensor.initialLoad) {
			SensorService.getMultiUseRunInfo(
				{s_id: sensor.id},
				RequestLogger.createLogData('sensor-analysis', 'load-multi-use-run-info', 'onLoad')
			).then(response => {
				const multiRunInfo = response.data.map((sensor, _) => new RunInfo(sensor));
				const multiRunData = response.data.map((sensor, index) => {
					//sensor.selected = index === 0;
					sensor.selected = sensor.sensors_id === this.state.sensor.id;
					if (sensor.selected) selidx = index;
					return sensor;
				});

				this.setState(
					{
						multiRunInfo,
						multiRunSensor: {
							...this.state.multiRunSensor,
							multiRunData,
							isMultiRunSensor: true,
						},
					},
					() => {
						this.setRun(true);
					}
				);
			});
		}
	};

	handleNewMultiRun = r => {
		isRunSet = false;
		this.setState(
			{
				id: r.SensorId,
				multiRunSensor: {
					...this.state.multiRunSensor,
					initialLoad: false,
				},
			},
			() => {
				this.setRun(false, () => {
					this.getData();
				});
			}
		);
	};

	getStatistics = () => {
		let that = this;

		let payload = {
			sensors_id: this.state.id,
			from: DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.dateFrom, DateTimeUtils.getCurrentUserTZOffset()),
			to: DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.dateTo, DateTimeUtils.getCurrentUserTZOffset()),
		};

		Statistics.getRunStatistics(payload, RequestLogger.createLogData('sensor-analysis', 'load-statistics', 'onLoad'))
			.then(function (response) {
				that.setState({statistics: response.data});
			})
			.catch(function (error) {
				console.log('error ', error);
			});
	};

	async getEventsForSensor() {
		const response = await SensorAnalysisService.getEventsForSensor(
			'?sensors_id=eq.' +
				this.state.id +
				'&date_occurred=gte.' +
				DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.dateFrom, DateTimeUtils.getCurrentUserTZOffset()) +
				'&date_occurred=lte.' +
				DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.dateTo, DateTimeUtils.getCurrentUserTZOffset()) +
				'&order=id',
			RequestLogger.createLogData('sensor-analysis', 'load-events-for-sensor', 'onLoad')
		);

		let events = [...response.data].reverse();

		let limitAlarmCount = 0;
		let sensorIssueCount = 0;
		let deviationEvents = events.filter(ev => ev.type === 24);
		let replaceSensorEvents = events.filter(ev => ev.type === 64);
		let sensorLimitAlarms = [];
		let sensorIssueAlarms = [];
		let sensorReplaces = [];

		deviationEvents.forEach(function (ev) {
			ev.details.forEach(function (detail) {
				if (
					detail.type === 5 /*entry reason*/ &&
					(detail.value === SENSOR_LIMIT_ALARMS.UPPER_LIMIT_ALARM[1] || detail.value === SENSOR_LIMIT_ALARMS.LOWER_LIMIT_ALARM[1])
				) {
					sensorLimitAlarms.push({tstamp: ev.date_occurred, desc: detail.value});
					limitAlarmCount++;
				} else if (
					detail.type === 5 /*entry reason*/ &&
					(detail.value === SENSOR_ISSUE_ALARMS.RADIO_CONNECTION_WARNING[1] ||
						detail.value === SENSOR_ISSUE_ALARMS.LOW_BATTERY_WARNING[1] ||
						detail.value === SENSOR_ISSUE_ALARMS.LOST_MEASUREMENT_ALARM[1] ||
						detail.value === SENSOR_ISSUE_ALARMS.MISSING_COMMUNICATION_WARNING[1] ||
						detail.value === SENSOR_ISSUE_ALARMS.MISSING_VALUE_ALARM[1] ||
						detail.value === SENSOR_ISSUE_ALARMS.SENSOR_FAILURE_ALARM[1])
				) {
					sensorIssueAlarms.push({tstamp: ev.date_occurred, desc: detail.value});
					sensorIssueCount++;
				}
			});
		});

		replaceSensorEvents.forEach(function (ev) {
			sensorReplaces.push({tstamp: ev.date_occurred, desc: REPLACE_SENSOR_EVENTS.REPLACE_SENSOR[0]});
		});

		this.setState({
			events: events,
			sensorLimitAlarms: sensorLimitAlarms,
			sensorReplaces: sensorReplaces,
			limitAlarmCount: limitAlarmCount,
			sensorIssueAlarms: sensorIssueAlarms,
			sensorIssueCount: sensorIssueCount,
		});
	}

	showLast7Days = e => {
		const last7Days = DateTimeUtils.getXDaysBeforeDay(7, undefined, true);
		this.setState(
			{
				dateFrom: last7Days.start.toDate(),
				dateTo: last7Days.end.toDate(),
				redrawChart: true,
				filterActive: this.state.moduleFamilyType === MODULE_FAMILIES.LIBERO_G,
			},
			this.getData
		);
		if (e) e.preventDefault();
	};

	showRun = e => {
		isRunSet = false;
		callGetData = true;
		this.setRun(false);
		if (e) e.preventDefault();

		this.setState({
			filterActive: this.state.moduleFamilyType !== MODULE_FAMILIES.LIBERO_G,
		});
	};

	setRun(reload, callback) {
		if (!isRunSet) {
			if (this.state.multiRunSensor && this.state.multiRunSensor.isMultiRunSensor) {
				let currMRS = this.state.multiRunSensor.multiRunData.find(r => r.sensors_id === this.state.id);
				let last7Days = DateTimeUtils.getLast7Days();

				const tsStart = currMRS.ts_start
					? DateTimeUtils.utcOffset_date_dep(currMRS.ts_start).format('YYYY-MM-DDTHH:mm') + '+00:00'
					: null;
				const tsStop = currMRS.ts_end
					? DateTimeUtils.utcOffset_date_dep(currMRS.ts_end).format('YYYY-MM-DDTHH:mm') + '+00:00'
					: null;

				this.setState(
					{
						startRun: tsStart,
						stopRun: tsStop,
						dateFrom: DateTimeUtils.utcOffset_date_dep(currMRS.ts_start),
						dateTo: currMRS.ts_end ? DateTimeUtils.utcOffset_date_dep(currMRS.ts_end) : last7Days.lastDayLast7Days,
						redrawChart: true,
					},
					reload ? this.getData : callback
				);
				isRunSet = true;
			}
		}

		if (isRunSet && callGetData) {
			isRunSet = false;

			const selectedIndex = this.state.multiRunSensor.multiRunData.findIndex(r => r.sensors_id === this.state.id);
			const updateSelectedRunData = this.state.multiRunSensor.multiRunData.map((item, index) => {
				item.selected = index === selectedIndex;
				return item;
			});

			let sensors_id = 0;
			if (updateSelectedRunData.length > 0) {
				sensors_id = updateSelectedRunData[selectedIndex].sensors_id;
			}

			this.setState(
				{
					id: sensors_id,
					multiRunSensor: {
						...this.state.multiRunSensor,
						multiRunData: updateSelectedRunData,
						initialLoad: false,
					},
				},
				() => {
					this.setRun(false, () => {
						this.getData();
					});
				}
			);
			callGetData = false;
		}
	}

	showLastWeek = e => {
		let lastWeek = DateTimeUtils.getLastWeek();
		this.setState(
			{
				dateFrom: lastWeek.firstDayLastWeek,
				dateTo: lastWeek.lastDayLastWeek,
				redrawChart: true,
				filterActive: true,
			},
			this.getData
		);
		e.preventDefault();
	};

	showLastMonth = e => {
		let lastMonth = DateTimeUtils.getLastMonth();
		this.setState(
			{
				dateFrom: lastMonth.firstDayLastMonth,
				dateTo: lastMonth.lastDayLastMonth,
				redrawChart: true,
				filterActive: true,
			},
			this.getData
		);
		e.preventDefault();
	};

	showLastYear = e => {
		let lastYear = DateTimeUtils.getLastYear();
		this.setState(
			{
				dateFrom: lastYear.firstDayLastYear,
				dateTo: lastYear.lastDayLastYear,
				redrawChart: true,
				filterActive: true,
			},
			this.getData
		);
		e.preventDefault();
	};

	showSelectedDateTime = e => {
		this.setState(
			{
				redrawChart: true,
				filterActive: true,
			},
			this.getData
		);
		e.preventDefault();
	};

	showPreviousTimePeriod = e => {
		const previousTimePeriod = DateTimeUtils.getPreviousTimePeriod(this.state.dateFrom, this.state.dateTo);
		this.setState(
			{
				dateFrom: previousTimePeriod.firstDay,
				dateTo: previousTimePeriod.lastDay,
				redrawChart: true,
			},
			this.getData
		);
		e.preventDefault();
	};

	showNextTimePeriod = e => {
		const nextTimePeriod = DateTimeUtils.getNextTimePeriod(this.state.dateFrom, this.state.dateTo);
		this.setState(
			{
				dateFrom: nextTimePeriod.firstDay,
				dateTo: nextTimePeriod.lastDay,
				redrawChart: true,
			},
			this.getData
		);
		e.preventDefault();
	};

	close = e => {
		const DevicesViewSettings = this.context.AccessContext.user.user_settings?.devicesViewSettings ?? {view: '/dashboard'};
		this.props.history.push(DevicesViewSettings.view);
		e.preventDefault();
	};

	getTransKey(type) {
		return 'auditTrail.events.' + AuditTrailEventTypes[type].translate;
	}

	handleChartSelection = event => {
		if (event.trigger === 'zoom' && event.userMin) {
			this.setState(
				{
					dateFrom: moment.utc(event.userMin, false),
					dateTo: moment.utc(event.userMax, false),
					redrawChart: true,
					filterActive: true,
				},
				() => {
					this.getData();
				}
			);
		}
	};

	resetZoom = () => {
		this.state.multiRunSensor.isMultiRunSensor ? this.showRun() : this.showLast7Days();
	};

	setMapDate = () => {
		this.setState({
			mapDateFrom: this.state.dateFrom,
			mapDateTo: this.state.dateTo,
		});
	};

	onTableRowExpand = (expanded, record) => {
		const keys = [];
		if (expanded) {
			keys.push(record.id);
		}
		this.setState({expandedKeys: keys});
	};

	render() {
		return (
			<HotKeys keyMap={keys} handlers={handlers}>
				<ContentWrapper>
					<Access
						access={accessPermissions.devicesview.child.dashboard.child.sensorAnalysis.child.selectPreviousSensorRuns}
						roles={userRoles.default}
					>
						<div className="content-heading">
							<div>{this.state.sensor.name}</div>
							<div className="ml-auto">{this.state.showCloseBtn && <Close onClick={this.close} />}</div>
						</div>
					</Access>
					<AntModal
						okText={'Copy to clipboard'}
						title="You can share this link"
						open={this.state.liveLinkOpen}
						onOk={() =>
							navigator.clipboard
								.writeText(this.state.liveLinkTxt)
								.then(() => this.setState({liveLinkOpen_sub: true, liveLinkOpen: false}))
						}
						onCancel={_ => this.setState({liveLinkOpen: false})}
						afterOpenChange={open =>
							open &&
							this.liveLinkInputRef.current.focus({
								cursor: 'all',
							})
						}
					>
						<Input type="text" value={this.state.liveLinkTxt} ref={this.liveLinkInputRef} />
					</AntModal>
					<AntModal
						footer={null}
						open={this.state.liveLinkOpen_sub}
						onCancel={_ => this.setState({liveLinkOpen_sub: false})}
						afterOpenChange={(open: boolean) => open && setTimeout(() => this.setState({liveLinkOpen_sub: false}), 1000)}
					>
						Link copied to clipboard!
					</AntModal>
					{this.state.moduleFamilyType === MODULE_FAMILIES.LIBERO_G ? (
						<Accordion>
							<AccordionItem id="accordionRun" expanded>
								<AccordionItemTitle id="accordionRunTitle">
									<h5 className="u-position-relative">
										<Trans i18nKey={'titles.run'} />
										<div className="accordion__arrow" role="presentation" />
									</h5>
								</AccordionItemTitle>
								<AccordionItemBody>
									<div style={{display: 'flex', justifyContent: 'space-between'}}>
										<div>
											{this.state.multiRunSensor.isMultiRunSensor &&
												!Authentication.isLiveLink() &&
												!this.state.showLastRunOnly && (
													<MultiRunDropdown
														runInfos={this.state.multiRunInfo}
														selectedRunId={this.state.id}
														onSelect={this.handleNewMultiRun}
													/>
												)}
										</div>

										<div style={{justifyContent: 'flex-end'}}>
											<ReportComponent
												debugReport={this.state.debugReport}
												reportLocalhost={this.reportLocalhost}
												loadingReport={this.state.loadingReport}
												report={this.report}
												exportFunction={this.exportData}
												exportShowGeo={this.state.sensor.has_map}
												liveLink={this.liveLink}
											/>
											{<AlarmStateComponent isAlarmed={this.state.alarmStatus} />}
										</div>
									</div>
								</AccordionItemBody>
							</AccordionItem>
						</Accordion>
					) : (
						<ReportComponent
							debugReport={this.state.debugReport}
							reportLocalhost={this.reportLocalhost}
							loadingReport={this.state.loadingReport}
							report={this.report}
							exportFunction={this.exportData}
							exportShowGeo={this.state.sensor.has_map}
							liveLink={this.liveLink}
						/>
					)}

					{this.state.showLastRunOnly ? (
						<div style={{padding: '5px'}}>
							<br />
						</div>
					) : (
						<AccordionFilter
							title={'Sensor Analysis Period'}
							expanded={true}
							dateFrom={this.state.dateFrom}
							setDateFrom={value => this.setState({dateFrom: value})}
							dateTo={this.state.dateTo}
							setDateTo={value => this.setState({dateTo: value})}
							showRun={this.state.multiRunSensor.isMultiRunSensor ? this.showRun : null}
							showLast7Days={this.showLast7Days}
							showLastWeek={this.showLastWeek}
							showLastMonth={this.showLastMonth}
							showLastYear={this.showLastYear}
							showSelectedDateTime={this.showSelectedDateTime}
							showPreviousTimePeriod={this.showPreviousTimePeriod}
							showNextTimePeriod={this.showNextTimePeriod}
							style={
								this.state.moduleFamilyType !== undefined &&
								this.state.moduleFamilyType !== null &&
								this.state.multiRunSensor.isMultiRunSensor &&
								this.state.moduleFamilyType === MODULE_FAMILIES.LIBERO_G
									? {marginTop: '20px'}
									: {marginTop: '70px'}
							}
						/>
					)}

					<br />

					<Accordion>
						<AccordionItem id="accordionSensorChart" expanded>
							<AccordionItemTitle id="accordionSensorChartTitle">
								<h5 className="u-position-relative">
									<Trans i18nKey={'titles.chart'} />
									<div className="accordion__arrow" role="presentation" />
								</h5>
							</AccordionItemTitle>
							<AccordionItemBody>
								{this.state.sensor.name !== undefined ? (
									<ChartComponent
										ref={this.chartComponent}
										sensor={this.state.sensor}
										moduleFamilyType={this.state.moduleFamilyType}
										measurements={this.state.measurements}
										sensorErrors={this.state.sensorErrors}
										sensorCalibrations={this.state.sensorCalibrations}
										occurrences={this.state.occurrences}
										otherOccurrences={this.state.otherOccurrences}
										limitAlarms={this.state.limitAlarms}
										sensorLimitAlarms={this.state.sensorLimitAlarms}
										sensorIssueAlarms={this.state.sensorIssueAlarms}
										sensorReplaces={this.state.sensorReplaces}
										dateFrom={this.state.dateFrom}
										dateTo={this.state.dateTo}
										startRun={this.state.startRun}
										stopRun={this.state.stopRun}
										deviationStart={null}
										deviationEnd={null}
										zoomType="x"
										handleChartSelection={this.handleChartSelection}
										resetZoom={this.resetZoom}
										filterActive={this.state.filterActive}
										handleChartPointClick={this.handleChartPointClick}
										outUnitsId={this.state.statistics.outUnitsId}
										redrawChart={this.state.redrawChart}
									/>
								) : null}
							</AccordionItemBody>
						</AccordionItem>
					</Accordion>
					{this.state.tilt.enabled && (
						<Accordion>
							<AccordionItem id="tiltValues" expanded>
								<AccordionItemTitle id="tiltValuesTitle">
									<h5 className="u-position-relative">
										<Trans i18nKey={'titles.tiltValues'} />
										<div className="accordion__arrow" role="presentation" />
									</h5>
								</AccordionItemTitle>
								<AccordionItemBody>
									<TiltGraph
										tiltValues={this.state.tilt.sensorTiltValues}
										filterActive={this.state.filterActive}
										handleChartSelection={this.handleChartSelection}
										resetZoom={this.resetZoom}
										forwardRef={this.tiltComponent}
									/>
								</AccordionItemBody>
							</AccordionItem>
						</Accordion>
					)}
					{this.state.predictive.enabled && (
						<Accordion>
							<AccordionItem id="predictiveAnalytics" expanded>
								<AccordionItemTitle id="predictiveAnalyticsTitle">
									<h5 className="u-position-relative">
										<Trans i18nKey={PredictiveTrans.title} />
										<div className="accordion__arrow" role="presentation" />
									</h5>
								</AccordionItemTitle>
								<AccordionItemBody>
									{this.state.sensor.name !== undefined ? (
										<PredictionResultView
											sensorPredictiveInfo={this.state.predictive.sensorPredictiveInfo}
											sensor={new Sensor(this.state.sensor)}
										/>
									) : null}
								</AccordionItemBody>
							</AccordionItem>
						</Accordion>
					)}

					{this.state.sensor.metadata && (
						<>
							<br />
							<Accordion>
								<AccordionItem id="sensorMetaData" expanded>
									<AccordionItemTitle id="accordionSensorMetaDataTitle">
										<h5 className="u-position-relative">
											Metadata
											<div className="accordion__arrow" role="presentation" />
										</h5>
									</AccordionItemTitle>
									<AccordionItemBody>
										<SensorMetaData sensor={this.state.sensor} />
									</AccordionItemBody>
								</AccordionItem>
							</Accordion>
						</>
					)}

					<br />

					{this.state.loadingContent && this.state.sensor.has_map ? (
						<>
							<Accordion>
								<AccordionItem id="accordionSensorMap" expanded>
									<AccordionItemTitle id="accordionSensorMapTitle">
										<h5 className="u-position-relative">
											Map
											<div className="accordion__arrow" role="presentation" />
										</h5>
									</AccordionItemTitle>
									<AccordionItemBody>
										<MapComponent
											forwardedRef={this.mapComponent}
											sensorId={this.state.id}
											sensor={this.state.sensor}
											dateFrom={this.state.mapDateFrom}
											dateTo={this.state.mapDateTo}
										/>
									</AccordionItemBody>
								</AccordionItem>
							</Accordion>
							<br />
						</>
					) : null}

					{this.state.accordionSensorStatistics.disabled ? (
						<div style={statisticsNotAvailableHeaderStyle}>{this.state.accordionSensorStatistics.title}</div>
					) : (
						<Accordion>
							<AccordionItem id="accordionSensorStatistics" expanded>
								<AccordionItemTitle id="accordionSensorStatisticsTitle">
									<h5 className="u-position-relative">
										{this.state.accordionSensorStatistics.title}
										<div className="accordion__arrow" role="presentation" />
									</h5>
								</AccordionItemTitle>
								<AccordionItemBody>
									{this.state.statistics.outUnitsId ? (
										<SensorAnalysisStatistics
											sensor={this.state.sensor}
											statistics={this.state.statistics}
											limitAlarmDetails={this.state.limitAlarmDetails}
											limitAlarmCount={this.state.limitAlarmCount}
											sensorIssueCount={this.state.sensorIssueCount}
											outUnitsId={this.state.statistics.outUnitsId}
										/>
									) : null}
								</AccordionItemBody>
							</AccordionItem>
						</Accordion>
					)}

					<br />

					<Accordion>
						<AccordionItem id="accordionRun" expanded>
							<AccordionItemTitle id="accordionRunTitle">
								<h5 className="u-position-relative">
									<Trans i18nKey={'titles.events'} />
									<div className="accordion__arrow" role="presentation" />
								</h5>
							</AccordionItemTitle>
							<AccordionItemBody>
								<AntStyledTable
									locale={{emptyText: 'No data'}}
									columns={this.columns}
									rowKey={record => record.id}
									dataSource={this.state.events}
									expandedRowKeys={this.state.expandedKeys}
									onExpand={this.onTableRowExpand}
									expandable={{
										expandRowByClick: true,
										expandedRowRender: (record, index, indent, expanded) => (
											<EventDetailList eventDetails={record.details.map(d => new EventDetail(d))} />
										),
									}}
								/>
							</AccordionItemBody>
						</AccordionItem>
					</Accordion>
				</ContentWrapper>
			</HotKeys>
		);
	}
}

export default SensorAnalysis;
