import {webserverServiceProUrl} from './index';
import {getDefaultOpts} from '../index';
import {IChecksumResponse, ITemplateChecksumRequest} from '@elproag/webserver-service-pro';

const baseUrl = `${webserverServiceProUrl}/api/web/devices`;

export async function CalculateConfigurationChecksum(configuration: ITemplateChecksumRequest): Promise<string> {
	const response = await fetch(`${baseUrl}/templates/checksum`, {
		...getDefaultOpts(),
		method: 'POST',
		body: JSON.stringify(configuration),
	});

	if (!response.ok) {
		console.error(await response.text());
		return Promise.reject(new Error(`Failed to calculate configuration checksum: ${response.statusText}`));
	}

	const result = (await response.json()) as IChecksumResponse;
	return result.checksum;
}

export async function CalculateDeviceChecksum(serialNumber: string, sensor_ids: number[]): Promise<string> {
	const queryParams = sensor_ids.map(id => `sensorIds=${id}`).join('&');
	const response = await fetch(`${baseUrl}/${serialNumber}/checksum?${queryParams}`, {
		...getDefaultOpts(),
		method: 'GET',
	});

	if (!response.ok) {
		console.error(await response.text());
		return Promise.reject(new Error(`Failed to calculate device checksum: ${response.statusText}`));
	}

	const result = (await response.json()) as IChecksumResponse;
	return result.checksum;
}
