import React, {ReactNode} from 'react';
import {Col, Row} from 'antd';
import styles from './styles.module.css';
import {KnowledgeBaseHelpMemo} from '../../Shared/Components/KnowledgeBaseHelp';
import {useTranslation} from 'react-i18next';

export function ViewHeader(
	props: Readonly<{
		heading?: string;
		insertSpaceBeforeChildren?: boolean;
		knowledgeHelpId?: string;
		children?: ReactNode | React.JSX.Element[];
	}>
): React.JSX.Element {
	const [t] = useTranslation();

	const insertSpaceBeforeChildren = props.insertSpaceBeforeChildren ?? true;

	return (
		<Row className={styles.Header} justify={'space-between'} align={'middle'}>
			<Col flex={1}>
				<Row gutter={16} align={'middle'} wrap={false}>
					{props.heading && (
						<Col flex={'0 1 auto'}>
							<h1 className="text-truncate">{t(props.heading).toString()}</h1>
						</Col>
					)}
					{props.knowledgeHelpId && (
						<Col>
							<KnowledgeBaseHelpMemo id={props.knowledgeHelpId} />
						</Col>
					)}

					{insertSpaceBeforeChildren && <Col flex={1}></Col>}

					{props.children}
				</Row>
			</Col>
		</Row>
	);
}
