import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SensorService from '../../Dashboard/Sensor/SensorService';
import {RequestLogger} from '../../Infrastructure/Requests/Logger';
import {SweetAlertDialogResult} from './Common/SweetAlertDialogResult';
import {DialogResult} from './Common/DialogResult';
import {SensorInfo} from '../../Services/Sensors/SensorInfo';
import {DeviceUtils} from '../../../common/util';
import {DeviceFamily} from '../../../common/constants';

export async function ShowDeleteSensorDialog(sensor: SensorInfo): Promise<DialogResult> {
	const deviceFamily = DeviceUtils.GetDeviceFamily(sensor.serial_number);

	const gxAdditionalInfo =
		[DeviceFamily.EcologProXG, DeviceFamily.LiberoGx].findIndex(family => family === deviceFamily) != -1
			? /* HTML */ `
					<div style="font-size:13px; line-height:25px;">
						<p>${deviceFamily} require a manual FCT.RESET on the device after deletion</p>
						<p>Before deleting your device, <span style="font-weight: bold">please read this information</span> carefully:</p>
						<p>
							<a href="https://www.elpro.com/en/support/elpro-cloud/delete" target="_blank" class="important-link">
								Effects of deleting your device</a
							>
						</p>
					</div>
			  `
			: '';

	const MySwal = withReactContent(Swal);
	const result = await MySwal.fire({
		title: "<span id='delete_sensor_in_dashboard'>Are you sure?</span>",
		html: "You won't be able to revert this!" + gxAdditionalInfo,
		input: 'password',
		inputAttributes: {
			autocapitalize: 'off',
			placeholder: 'Enter your password',
		},
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#d33',
		cancelButtonColor: '#3085d6',
		confirmButtonText: 'Yes, delete it!',
		preConfirm: password_1 => {
			return SensorService.deleteSensor(
				{
					sensors_id: sensor.id,
					pass: password_1,
				},
				RequestLogger.createLogData('dashboard', 'delete-sensor', 'onClick')
			).catch(error => {
				if (error.data) {
					const msg = error.data.message;
					if (msg === 'error.invalidPassword') MySwal.showValidationMessage('Invalid Password');
					else MySwal.showValidationMessage(msg);
				} else MySwal.showValidationMessage('Operation failed');
			});
		},
	});
	return new SweetAlertDialogResult(result);
}
