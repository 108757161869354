import React, {ReactNode} from 'react';
import styles from './styles/styles.module.scss';

export function ContentWrapper(props: Readonly<{children: ReactNode | ReactNode[]}>): ReactNode {
	return <div className={styles.contentWrapper}>{props.children}</div>;
}

interface ContentWrapperDeprecatedProps {
	/** add element with 'unwrap' class to expand content area */
	unwrap?: boolean;
	children: ReactNode | ReactNode[];
}

/**
 * Wrapper element for template content
 */
const ContentWrapperDeprecated = (props: ContentWrapperDeprecatedProps) => (
	<div className="content-wrapper">{props.unwrap ? <div className="unwrap">{props.children}</div> : props.children}</div>
);

ContentWrapperDeprecated.defaultProps = {
	unwrap: false,
};

export default ContentWrapperDeprecated;
