import React from 'react';
import {Access} from '../../Infrastructure/Authorization/Components';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';
import {ActionButton, ActionButtonAction} from '../../Shared/Components/ActionButton';
import {AlarmLimit} from '../../../common/types';
import {useLimitProfileActions} from '../useLimitProfileActions';

export function LimitProfileGridActions(props: Readonly<{limitProfile: AlarmLimit}>): React.ReactNode {
	const {copyLimitProfile, deleteLimitProfile, editLimitProfile} = useLimitProfileActions();
	return (
		<>
			<Access
				access={accessPermissions.settings.child.limitProfiles.child.limitProfile.child.addLimitProfile}
				roles={userRoles.default}
			>
				<ActionButton
					id={'btnCopy-' + props.limitProfile.Id}
					actionType={ActionButtonAction.Copy}
					onClick={() => {
						const newProfile = structuredClone(props.limitProfile);
						delete newProfile.Id;
						delete newProfile.Name;
						copyLimitProfile(newProfile);
					}}
					locked={false}
				/>
			</Access>
			<Access
				access={accessPermissions.settings.child.limitProfiles.child.limitProfile.child.editLimitProfile}
				roles={userRoles.default}
			>
				<ActionButton
					id={'btnEdit-' + props.limitProfile.Id}
					actionType={ActionButtonAction.Edit}
					onClick={() => {
						editLimitProfile(props.limitProfile);
					}}
					locked={props.limitProfile.Locked}
				/>
			</Access>
			<Access
				access={accessPermissions.settings.child.limitProfiles.child.limitProfile.child.deleteLimitProfile}
				roles={userRoles.default}
			>
				<ActionButton
					id={'btnDelete-' + props.limitProfile.Id}
					actionType={ActionButtonAction.Delete}
					onClick={() => deleteLimitProfile(props.limitProfile)}
					locked={props.limitProfile.Locked}
				/>
			</Access>
		</>
	);
}
