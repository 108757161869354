import {LimitAlarmFormState} from '../Forms/LimitAlarmForm/LimitAlarmFormState';

export function AlarmLimitToLimitProfile(limitAlarmFormState: LimitAlarmFormState): any {
	return {
		use_limit_alarms: !!limitAlarmFormState?.alarmLimit,
		limit_alarm_name: limitAlarmFormState?.alarmLimit?.Name ?? null,
		id: limitAlarmFormState?.alarmLimit?.Id ?? null,
		upper_limit: limitAlarmFormState?.alarmLimit?.UpperLimits[0] ?? null,
		upper_limit_2: limitAlarmFormState?.alarmLimit?.UpperLimits[1] ?? null,
		upper_limit_3: limitAlarmFormState?.alarmLimit?.UpperLimits[2] ?? null,
		upper_limit_4: limitAlarmFormState?.alarmLimit?.UpperLimits[3] ?? null,
		lower_limit: limitAlarmFormState?.alarmLimit?.LowerLimits[0] ?? null,
		lower_limit_2: limitAlarmFormState?.alarmLimit?.LowerLimits[1] ?? null,
		lower_limit_3: limitAlarmFormState?.alarmLimit?.LowerLimits[2] ?? null,
		upper_limit_delay: limitAlarmFormState?.alarmLimit?.UpperLimitDelays[0] ?? null,
		upper_limit_delay_2: limitAlarmFormState?.alarmLimit?.UpperLimitDelays[1] ?? null,
		upper_limit_delay_3: limitAlarmFormState?.alarmLimit?.UpperLimitDelays[2] ?? null,
		upper_limit_delay_4: limitAlarmFormState?.alarmLimit?.UpperLimitDelays[3] ?? null,
		lower_limit_delay: limitAlarmFormState?.alarmLimit?.LowerLimitDelays[0] ?? null,
		lower_limit_delay_2: limitAlarmFormState?.alarmLimit?.LowerLimitDelays[1] ?? null,
		lower_limit_delay_3: limitAlarmFormState?.alarmLimit?.LowerLimitDelays[2] ?? null,
		upper_limit_warning: limitAlarmFormState?.alarmLimit?.UpperLimitWarning ?? null,
		upper_limit_warning_delay: limitAlarmFormState?.alarmLimit?.UpperLimitWarningDelay ?? null,
		lower_limit_warning: limitAlarmFormState?.alarmLimit?.LowerLimitWarning ?? null,
		lower_limit_warning_delay: limitAlarmFormState?.alarmLimit?.LowerLimitWarningDelay ?? null,
		limit_alarm_creation_type: limitAlarmFormState?.alarmLimit?.Id ?? null,
		email_notification: limitAlarmFormState?.recipients.email.enabled ?? false,
		email_users: limitAlarmFormState?.recipients.email.recipients.map(u => u.Id) ?? [],
		sms_notification: limitAlarmFormState?.recipients.sms.enabled ?? false,
		sms_users: limitAlarmFormState?.recipients.sms.recipients.map(u => u.Id) ?? [],
	};
}
