import {IServerSideGetRowsRequest} from 'ag-grid-enterprise';
import DateTimeUtils from '../../../components/Infrastructure/DateTime/DateTimeUtils';
import dayjs from 'dayjs';

export function getSortParms(params: IServerSideGetRowsRequest): string {
	let sortParms = '';
	params.sortModel.forEach(sortDefinition => {
		sortParms += `&order=${sortDefinition.colId.toLowerCase()}.${sortDefinition.sort}.${
			sortDefinition.sort === 'asc' ? 'nullsfirst' : 'nullslast'
		}`;
	});

	return sortParms;
}

export function getFilterParams(params: IServerSideGetRowsRequest): string {
	let filterParams = '';
	if (params.filterModel) {
		Object.keys(params.filterModel).forEach(function (key) {
			const item = params.filterModel[key];

			const filterTypes: {[key: string]: (key: any, item: any) => string} = {
				text: setupTextFilter,
				number: setupNumberFilter,
				date: setupDateFilter,
				set: setupSetFilter,
			};

			const setupFilter = filterTypes[item.filterType];
			if (setupFilter) {
				filterParams += item.operator ? mapFilters(key, item, setupFilter) : mapFilter(key, item, setupFilter);
			} else {
				console.error('unknown filter type: ' + item.filterType);
			}
		});
	}

	if (params.groupKeys) {
		params.groupKeys.forEach(function (key, i) {
			filterParams += `&${params.rowGroupCols[i].id}=eq.${key}`;
		});
	}

	return filterParams;
}

function setupTextFilter(key: any, item: {type: string; filter: any}) {
	switch (item.type) {
		case 'equals':
			return `${key}.eq.${item.filter}`;
		case 'notEqual':
			return `${key}.isdistinct.${item.filter}`;
		case 'contains':
			return `${key}.ilike.*${item.filter}*`;
		case 'notContains':
			return `${key}.not.ilike.*${item.filter}*`;
		case 'startsWith':
			return `${key}.ilike.${item.filter}*`;
		case 'endsWith':
			return `${key}.ilike.*${item.filter}`;
		case 'blank':
			return `${key}.is.null`;
		case 'notBlank':
			return `not.and(${key}.is.null)`;
		default:
			console.log('unknown text filter type: ' + item.type);
	}
}

function setupNumberFilter(key: any, item: {type: string; filter: any; filterTo: any}) {
	switch (item.type) {
		case 'equals':
			return `${key}.eq.${item.filter}`;
		case 'notEqual':
			return `${key}.neq.${item.filter}`;
		case 'greaterThan':
			return `${key}.gt.${item.filter}`;
		case 'greaterThanOrEqual':
			return `${key}.gte.${item.filter}`;
		case 'lessThan':
			return `${key}.lt.${item.filter}`;
		case 'lessThanOrEqual':
			return `${key}.lte.${item.filter}`;
		case 'inRange':
			return `and(${key}.gte.${item.filter}, ${key}.lte.${item.filterTo})`;
		case 'blank':
			return `${key}.is.null`;
		case 'notBlank':
			return `not.and(${key}.is.null)`;
		default:
			console.log('unknown number filter type: ' + item.type);
	}
}

function setupDateFilter(key: any, item: {type: string; dateFrom: any; dateTo: any}) {
	const fromUTC =
		item.type !== 'blank' && item.type !== 'notBlank'
			? DateTimeUtils.getIsoFromUCTWithoutOffset(item.dateFrom, DateTimeUtils.getCurrentUserTZOffset())
			: null;
	const toUTC =
		item.type === 'inRange' && item.dateTo
			? DateTimeUtils.getIsoFromUCTWithoutOffset(item.dateTo, DateTimeUtils.getCurrentUserTZOffset())
			: null;

	const queryDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
	switch (item.type) {
		case 'equals':
			return `and(${key}.gte.${fromUTC}, ${key}.lt.${dayjs(fromUTC).add(1, 'day').format(queryDateTimeFormat)})`;
		case 'notEqual':
			return `or(${key}.lt.${fromUTC}, ${key}.gt.${dayjs(fromUTC).add(1, 'day').format(queryDateTimeFormat)})`;
		case 'greaterThan':
			return `${key}.gt.${dayjs(fromUTC).add(1, 'day').format(queryDateTimeFormat)})`;
		case 'lessThan':
			return `${key}.lt.${fromUTC}`;
		case 'inRange':
			return `and(${key}.gte.${fromUTC}, ${key}.lte.${toUTC})`;
		case 'blank':
			return `${key}.is.null`;
		case 'notBlank':
			return `not.and(${key}.is.null)`;
		default:
			console.log('unknown date filter type: ' + item.type);
	}
}

function setupSetFilter(key: any, item: {values: any[]}) {
	if (key.endsWith('_array')) {
		return `${key}.ov.{${item.values.join(',')}}`;
	}
	return `${key}.in.(${item.values.join(',')})`;
}

function mapFilters(
	key: any,
	item: {conditions: {type: string; filter: any}[]; operator: any},
	setupFilter: (key: any, item: any) => string
) {
	const conditionList = Object.keys(item)
		.filter(key => key.startsWith('condition'))
		.map(key => item[key]);

	const conditions = conditionList.map((condition: {type: string; filter: any}) => setupFilter(key, condition));
	return `&${item.operator.toLowerCase()}=(${conditions.join(',')})`;
}

function mapFilter(key: any, item: {type: string; filter: any}, setupFilter: (key: any, item: any) => string) {
	return `&and=(${setupFilter(key, item)})`;
}
