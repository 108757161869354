import React, {ReactNode, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ContentWrapper, ViewWrapper} from '../Layout';
import {Card, ViewHeader} from '../Common';
import {useLocation, useParams} from 'react-router-dom';
import {AlarmLimit} from '../../common/types';
import {LimitProfileService} from '../../common/services';
import {SensorMappingsTable} from './SensorMappingsTable';
import {Access} from '../Infrastructure/Authorization/Components';
import {AccessPermissions, UserRoles} from '../Infrastructure/Authorization/Access';
import {Button, Col} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {LimitProfileForm} from './LimitProfileForm';
import {useLimitProfileActions} from './useLimitProfileActions';

export function LimitProfileDetail() {
	const [t] = useTranslation();
	const location = useLocation<{limitProfile?: AlarmLimit; editing?: boolean}>();
	const {viewAllLimitProfiles} = useLimitProfileActions();
	const params = useParams();
	const profileId = params['profileId'];
	const isNew = !profileId;
	const [limitProfile, setLimitProfile] = useState<AlarmLimit>(location.state?.limitProfile ?? new AlarmLimit());
	const [editing, setEditing] = useState(location.state?.editing || isNew);

	useEffect(() => {
		if (profileId && profileId != limitProfile.Id) LimitProfileService.Get(profileId).then(setLimitProfile);
	}, [profileId]);

	function onCancelClicked() {
		if (isNew) viewAllLimitProfiles();
		setEditing(false);
	}

	function getButtons(): ReactNode {
		return (
			<Access access={AccessPermissions.settings.child.organization.child.editOrganization} roles={UserRoles.default}>
				{editing ? (
					<Col>
						<Button onClick={onCancelClicked} size={'large'}>
							{t('global.cancel').toString()}
						</Button>
					</Col>
				) : (
					!limitProfile.Locked && (
						<Col>
							<Button type={'primary'} icon={<EditOutlined />} size={'large'} onClick={() => setEditing(true)}>
								{t('global.edit').toString()}
							</Button>
						</Col>
					)
				)}
				{!isNew && (
					<Col>
						<Button onClick={viewAllLimitProfiles} size={'large'}>
							{t('buttons.close').toString()}
						</Button>{' '}
					</Col>
				)}
			</Access>
		);
	}

	return (
		limitProfile && (
			<ViewWrapper>
				<ViewHeader heading={t('settings.limitProfiles.title')}>{getButtons()}</ViewHeader>
				<ContentWrapper>
					<Card title="Profile">
						<LimitProfileForm limitProfile={limitProfile} editing={editing} viewOnly={limitProfile.Locked} />
					</Card>

					{!isNew && (
						<Card title={t('settings.limitProfiles.mappings').toString()}>
							<SensorMappingsTable sensors={limitProfile.LinkedSensors} />
						</Card>
					)}
				</ContentWrapper>
			</ViewWrapper>
		)
	);
}
