import request from '../Infrastructure/Requests/Request';
import AuditTrailEventTypes from './AuditTrailEventTypes';
import Authentication from '../Infrastructure/Authentication/Authentication';

function events(params, eventData) {
    let url = '/events';
    let filterActive = false;

    if (params.incidents_id !== null && params.incidents_id !== undefined) {
        filterActive = true;
        switch (params.incident_filter) {
            case 'equal':
                url += '?incidents_id=eq.' + params.incidents_id[0];
                break;
            case 'between':
                const test = /^[0-9]+-[0-9]+$/.test(params.incidents_id[0]);
                if (test) {
                    const range = params.incidents_id[0];
                    url +=
                        '?or=(and(incidents_id.lte.' +
                        range.split('-')[0] +
                        ',incidents_id.gte.' +
                        range.split('-')[1] +
                        '),and(incidents_id.lte.' +
                        range.split('-')[1] +
                        ',incidents_id.gte.' +
                        range.split('-')[0] +
                        '))';
                } else {
                    url += '?incidents_id.desc';
                }
                break;
            case 'list':
                url += '?incidents_id=in.(' + params.incidents_id[0].replace(/,(\s+)?$/, '') + ')';
                break;
            case 'above':
                url += '?incidents_id=gte.' + params.incidents_id[0];
                break;
            case 'below':
                url += '?incidents_id=lte.' + params.incidents_id[0];
                break;
            default:
                url += '';
        }
    }

    if (params.type !== null && params.type !== undefined) {
        if (filterActive) {
            url += '&';
        } else {
            url += '?';
            filterActive = true;
        }

        const typeTextSearch = [];
        const typeSearch = [];

        params.type.forEach(param => {
            const found = AuditTrailEventTypes.findIndex(x => x.translate === param);
            if (found === -1) {
                typeTextSearch.push('type_text.like.*' + param + '*');
            } else {
                typeSearch.push(found);
            }
        });

        if (typeTextSearch.length) {
            url += 'or=(' + typeTextSearch.toString() + ')';
        }

        if (typeSearch.length) {
            if (typeTextSearch.length) {
                url += '&';
            }
            url += 'type=in.(' + typeSearch.toString() + ')';
        }
    }

    if (!filterActive) {
        url += '?';
    } else {
        url += '&';
    }

    if (params.sortField === undefined) {
        url += 'order=date_occurred.desc,incidents_id.desc';
    } else {
        url += 'order=' + params.sortField + '.' + (params.sortOrder === 'ascend' ? 'asc' : 'desc') + ',incidents_id.desc';
    }

    if (params.from !== undefined && params.to !== undefined) {
        url += '&date_occurred=gte.' + params.from + '&date_occurred=lte.' + params.to;
    }

    return request({
        url: url,
        method: 'GET',
        token: Authentication.getToken(),
        eventData,
    });
}

function auditTrailReport(payload, eventData) {
    return request({
        url: '/report/auditTrail',
        method: 'POST',
        data: payload,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
        },
        token: Authentication.getToken(),
        eventData,
    });
}

function auditTrailEventDetailTypesTransKey() {
    return request({
        url: '/event_detail_types_trans_key',
        method: 'GET',
        token: Authentication.getToken(),
    });
}

function auditTrailEventTypesTransKey() {
    return request({
        url: '/event_types_trans_key',
        method: 'GET',
        token: Authentication.getToken(),
    });
}

const AuditTrailService = {
    events,
    auditTrailReport,
    auditTrailEventDetailTypesTransKey,
    auditTrailEventTypesTransKey,
};

export default AuditTrailService;
