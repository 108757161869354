import {FormStates} from '../../../../components/Wizard/Types';
import {
	AdditionalConfiguration,
	EcologProXGMetaDatasForm,
	SensorBasicsForm,
	SensorIssuesForm,
	WizardStateSummaryForm,
} from '../AddSensor/WizardState';
import {CreditCard} from '../../../../common/types';
import ModuleForm from '../Forms/ModuleForm';
import {WizardGetSensors} from './WizardGetSensors';
import {GetChannelTypes} from './GetChannelTypes';
import {DeviceUtils} from '../../../../common/util';
import {SmsAddonRequired} from './SmsAddonRequired';
import {mapCommunicationModeTemplateToAdditionalConfiguration} from '../../../../components/Forms/Mappers/CommunicationModes/CommunicationModeTemplateMapper';
import {CalculateConfigurationChecksum} from '../../../../common/services/WebserverServicePro/DeviceService';

export async function prepareConnectModuleRequest(
	sensorBasicsForm: SensorBasicsForm,
	moduleForm: ModuleForm,
	formStates: FormStates,
	sensorIssuesForm: SensorIssuesForm,
	summaryForm: WizardStateSummaryForm,
	offering: {actual_price: any},
	tiltEnabled: boolean,
	additionalConfiguration?: AdditionalConfiguration,
	metadatasForm?: EcologProXGMetaDatasForm,
	creditCard?: {credit_card: CreditCard; vouchers: any; redeem_prepaid: boolean}
) {
	let data = {
		module: moduleForm,
		sensors: WizardGetSensors(
			moduleForm.serial_number,
			sensorBasicsForm,
			formStates.limitAlarmForms,
			GetChannelTypes(sensorBasicsForm, DeviceUtils.GetDeviceFamily(moduleForm.serial_number)),
			metadatasForm
		),
		sensor_issue_alarm: sensorIssuesForm,
		payment_information: creditCard
			? {
					credit_card: creditCard,
					vouchers: summaryForm.vouchers,
					redeem_prepaid: creditCard.redeem_prepaid,
					logging_interval: sensorBasicsForm.logging_interval,
					sms_notifications: SmsAddonRequired(
						moduleForm.serial_number,
						sensorBasicsForm.selectedChannel?.selectedChannel,
						sensorBasicsForm.selectedChannel?.selectedValueIndex,
						formStates?.limitAlarmForms,
						sensorIssuesForm
					),
					offer_total: DeviceUtils.IsLiberoGx(moduleForm.serial_number) === true ? 0 : offering?.actual_price,
			  }
			: null,
		additional_configuration: null,
	};

	if (additionalConfiguration?.has_additional_configuration) {
		let addConfig = JSON.parse(JSON.stringify(additionalConfiguration.configuration_template_result));

		let useAlarming = formStates.limitAlarmForms?.some(l => !!l.alarmLimit);

		if (useAlarming) {
			addConfig['alarming_activation_visible'] = true;
			if (DeviceUtils.IsLiberoGx(moduleForm.serial_number)) {
				addConfig['alarming_pausing_allowed'] = true;
			}
		} else {
			addConfig['alarming_activation_visible'] = false;
			if (DeviceUtils.IsLiberoGx(moduleForm.serial_number)) {
				addConfig['alarming_pausing_allowed'] = false;
			}
		}

		addConfig = mapCommunicationModeTemplateToAdditionalConfiguration(
			sensorBasicsForm.communication_mode,
			additionalConfiguration.communication_mode_templates,
			addConfig
		);

		if (tiltEnabled) {
			const tilt_det = {
				enable: false,
				reference_x: 0.0,
				reference_y: 0.0,
				reference_z: 1.0,
				limit_angle: 180,
			};
			addConfig = {...addConfig, tilt_detection: tilt_det};
		}

		data = {...data, additional_configuration: addConfig};

		addConfig['config_id'] = await CalculateConfigurationChecksum(data);
	}
	return data;
}
