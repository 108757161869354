import React, {ReactNode} from 'react';
import {RADIO_STRENGTH_ENUM, RADIO_STRENGTH_STRINGS} from './Constants/RadioStrengthEnums';

export function SignalStrengthComponent(props: {signalStrength: RADIO_STRENGTH_ENUM}): ReactNode {
	const signalStrengthClass = RADIO_STRENGTH_STRINGS[props.signalStrength];
	return signalStrengthClass === null ? null : (
		<span className={signalStrengthClass} style={{marginLeft: '1px', fontSize: '20px', verticalAlign: 'middle'}}>
			<span className="path1" />
			<span className="path2" />
			<span className="path3" />
			<span className="path4" />
			<span className="path5" />
		</span>
	);
}
