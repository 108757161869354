import findFamilyTypeFromTemplateName from '../../../Shared/Extensions/DeviceModuleFamilyTypeFinder';
import Shared from '../../../Shared/Shared';
import FormBuilder from '../../../Forms/FormBuilder';
import React, {Component} from 'react';
import {Trans} from 'react-i18next';
import {GetSensorSteps} from '../AddSensor/GetSensorSteps';
import RegistryHelper, {showLiberoGWarning} from '../Utils/RegistryHelper';
import RadialSteps from '../../../Common/RadialSteps';
import pubsub from 'pubsub-js';
import {MultiContext} from '../../../Infrastructure/Authorization/Context/MultiContext';
import {CurrentPriceExtension, CurrentPriceProps, CurrentPriceResult} from '../../../../common/util/LicenseUtils/CurrentPriceExtension';

// Services
import IssueProfileService from '../../../Services/IssueProfiles/IssueProfilesService';
import BillingService from '../../../Settings/Billing/BillingService';
import EditSensorService from '../SensorService';
import LegacySensorService from '../SensorService';
import {SensorService} from '../../../../common/services';

// Forms
import SensorBasicsFormComponent from '../Forms/SensorBasicsForm/SensorBasicsFormComponent';
import LimitAlarmsForm from '../Forms/LimitAlarmForm/LimitAlarmsForm';
import SensorIssuesFormComponent from '../Forms/SensorIssueForm/SensorIssuesFormComponent';
import SensorSummaryFormComponent from '../Forms/SensorSummary/SensorSummaryFormComponent.js';
import ConfigurationTemplatesForm from '../Forms/ConfigurationTemplatesForm';

import {
	DiscoverModuleChannelTypeToChannelType,
	getFreeChannelsFromModule,
	getUsedChannelDataFromModule,
	SortChannelTypes,
} from '../Utils/DiscoverModule';
import {RequestLogger} from '../../../Infrastructure/Requests/Logger';
import {
	getSensorBasicsFormSettingsForReConfiguration,
	getSensorPaymentMethodSettingsForReConfiguration,
	getSensorSummarySettingsForReConfiguration,
} from '../Helpers/WizardSettingsBuilder';
import LiberoGxMapper from '../../../Forms/Mappers/LiberoGMapper/LiberoGxMapper';
import {mapCommunicationModeTemplateToAdditionalConfiguration} from '../../../Forms/Mappers/CommunicationModes/CommunicationModeTemplateMapper';
import {RouteChildrenProps} from 'react-router-dom';
import {EditLiberoGxState} from './EditLiberoGxState';
import {ViewHeader} from '../../../Common';
import {ContentWrapper} from '../../../Layout';
import {Button, Col, Row} from 'antd';
import {FormNavigation} from '../../../Wizard/Elements';
import {PredictiveForm, PredictiveTrans} from '../../../Wizard';
import {PredictiveFormState} from '../../../Wizard/Forms/Predictive/PredictiveForm';
import {LimitAlarmFormState} from '../Forms/LimitAlarmForm/LimitAlarmFormState';
import {StartRunMultiResponse} from '../../../../common/services/SensorService/StartRunMultiResponse';
import {ExportFormStateAndSteps, SetUpPredictive, WizardNavigationUtil} from '../../../Wizard/Util';
import {FeatureFlag} from '../../../../common/helpers';
import {Template} from 'common/types/DbModel';
import {AlarmLimit, User} from '../../../../common/types';
import {IssueProfile, SensorBasicsForm, SensorBasicsFormChannel} from '../AddSensor/WizardState';
import {GetChannelTypes} from '../Helpers/GetChannelTypes';
import {SmsAddonRequired} from '../Helpers/SmsAddonRequired';
import {DeviceUtils} from '../../../../common/util';
import {DiscoverModuleResponse} from '../../../../common/services/SensorService/DiscoverModuleResponse';
import {
	GetInitialLicenseOfferingResult,
	GetLicenseOffering,
	PrepareLicenseOfferingFromLineItemTypes,
	SetUpCheckoutSession,
	SetUpSubscriptionUpdate,
} from '../../../Wizard/Util/LicensingUtils';
import {InternalProductCategories} from '../../../../common/util/LicenseUtils/LineItemConstants';
import {CalculateConfigurationChecksum} from '../../../../common/services/WebserverServicePro/DeviceService';
import {prepareConnectModuleRequest} from '../Helpers/SensorConnectRequestBuilder';

const LiberoGxWizard = LiberoGxMapper(FormBuilder);

class EditLiberoGx extends Component<RouteChildrenProps, EditLiberoGxState> {
	static contextType = MultiContext;

	declare context: React.ContextType<typeof MultiContext>;

	constructor(props) {
		super(props);
		this.state = {
			currentChannelIndex: null,
			currentValueIndex: null,
			isLiberoG: true,
			loading: false,
			additionalConfiguration: {
				has_additional_configuration: false,
				configuration_templates: null,
				configuration_template: null,
				configuration_template_result: null,
				communication_mode_templates: null,
				additional_configuration_forms: null,
			},
			currentLimitAlarmsFormIndex: 0,
			current_step: 0,
			formStates: null,
			is_libero_g: true,
			issueProfiles: [],
			licenseOffering: {
				baseLicenses: [],
				loggingIntervals: [],
				limitEmail1: undefined,
				limitEmail2: undefined,
				limitSms1: undefined,
				limitSms2: undefined,
				issueEmail: undefined,
				issueSms: undefined,
				retirement1: undefined,
				retirement2: undefined,
			},
			licenseOfferingResult: undefined,
			moduleForm: {
				serial_number: props.location.state.serial_number,
			},
			offering: undefined,
			hasSubscription: false,
			sensorBasicsForm: {
				communication_mode: undefined,
				last_run_only: false,
				disableChangeLogging: false,
				isConfiguration: true,
				sensor_name: '',
				customer_id: '',
				logging_interval: 600,
				communication_interval: 120,
				unit: this.context?.AuthContext?.Organization?.DefaultTemperature,
				defaultSettingUnit: this.context?.AuthContext?.Organization?.DefaultTemperature,
				memo: '',
				firmware: '',
				active: false,
				moduleData: null,
				currentModuleChannels: null,
				selectedChannel: null,
			},
			sensorIssuesForm: {
				use_issue_alarms: false,
				issue_alarms: [],
				limit_issue_name: '',
				missing_value_alarm: false, //not used anymore
				missing_value_alarm_delay: 0, //not used anymore
				sensor_failure_alarm: false,
				sensor_failure_alarm_delay: 0,
				radio_connection_warning: false, //Not used anymore https://elproag.atlassian.net/browse/TH-460
				radio_connection_warning_delay: 0, //Not used anymore https://elproag.atlassian.net/browse/TH-460
				radio_connection_warning_threshold: -90, //Not used anymore https://elproag.atlassian.net/browse/TH-460
				low_battery_warning: false,
				email_notification: false,
				email_users: [],
				sms_notification: false,
				sms_users: [],
				threshold_option: 0,
				editable: true,
			},
			sensors: [],
			steps: GetSensorSteps(false, null, true),
			summaryForm: {
				start_sensor: false,
				vouchers: [],
				offering: undefined,
				errorMessage: '',
				terms_of_use: false,
				disable_sensor_update_msg: '',
			},
			templateName: '',
			uncounted_steps: 1,
		};
	}

	componentDidMount() {
		this.getModuleInformation();
		this.getIssueProfiles();
		this.getLicenseOfferings();
	}

	getModuleInformation() {
		let that = this;

		const channelCollection = {channelsUsed: [], channelsFree: []};

		let gets = [
			EditSensorService.sensor(this.props.location.state['sensors'][0].id),
			this.props.location.state['sensors'].length > 1 ? EditSensorService.sensor(this.props.location.state['sensors'][1].id) : null,
			SensorService.DiscoverModule(this.props.location.state['serial_number']),
		];

		let parsedData = JSON.parse(JSON.stringify(this.props.location.state['additional_configuration'].additional_configuration));

		Promise.all(gets).then(responses => {
			const sensorData1 = responses[0].data;
			const sensorData2 = this.props.location.state['sensors'].length > 1 ? responses[1].data : null;
			const moduleData = responses[2] as DiscoverModuleResponse;

			const freeChannelsToUse = getFreeChannelsFromModule(
				moduleData.module.channels,
				moduleData.module.used_channels,
				moduleData.channel_types
			);

			channelCollection.channelsUsed = getUsedChannelDataFromModule(
				freeChannelsToUse,
				moduleData.module.used_channels,
				moduleData.channel_types,
				moduleData.units
			);

			this.sortChannels(freeChannelsToUse);
			this.sortChannels(channelCollection.channelsUsed);

			const channel = moduleData.module.channels[0];

			const allChannelTypes = SortChannelTypes(
				moduleData.channel_types.map(c => DiscoverModuleChannelTypeToChannelType(c, moduleData))
			);

			const selectedChannelTypes = SortChannelTypes(
				moduleData.channel_types
					.filter(c => c.channel_type === channel.channel_type)
					.map(c => DiscoverModuleChannelTypeToChannelType(c, moduleData))
			);

			const selectedChannel: SensorBasicsFormChannel = {
				channel_types: selectedChannelTypes,
				selectedChannel: moduleData.module.used_channels[0].channel_number,
				selectedValueIndex: moduleData.module.used_channels[0].value_index,
			};

			const hasConfigurationTemplates =
				!!moduleData.module.configuration_templates && moduleData.module.configuration_templates.length > 0;

			const configTemplateName = findFamilyTypeFromTemplateName(
				moduleData.module.configuration_templates !== undefined ? moduleData.module.configuration_templates[0].name : null
			);

			const limitAlarmForms: LimitAlarmFormState[] = [
				{
					alarmLimit: sensorData1.has_limit_alarm ? new AlarmLimit({id: sensorData1.sensor_limit_alarm_id}) : null,
					predictive: {enabled: false},
					recipients: {
						email: {
							enabled: sensorData1.sensor_limit_email_notification,
							recipients: sensorData1.recipients_email_list.limit_recipients?.map((id: number) => new User({id: id})),
						},
						sms: {
							enabled: sensorData1.sensor_limit_sms_notification,
							recipients: sensorData1.recipients_sms_list.limit_recipients?.map((id: number) => new User({id: id})),
						},
					},
					unit: parseInt(sensorData1.out_units_id),
				},
			];

			if (sensorData2) {
				limitAlarmForms.push({
					alarmLimit: sensorData2.has_limit_alarm ? new AlarmLimit({id: sensorData2.sensor_limit_alarm_id}) : null,
					predictive: {enabled: false},
					recipients: {
						email: {
							enabled: sensorData2.sensor_limit_email_notification,
							recipients: sensorData2.recipients_email_list.limit_recipients?.map((id: number) => new User({id: id})),
						},
						sms: {
							enabled: sensorData2.sensor_limit_sms_notification,
							recipients: sensorData2.recipients_sms_list.limit_recipients?.map((id: number) => new User({id: id})),
						},
					},
					unit: parseInt(sensorData2.out_units_id),
				});
			}

			const sensorBasicsForm: SensorBasicsForm = {
				...this.state.sensorBasicsForm,
				sensor_name: parsedData.metadatas.metadata_1,
				last_run_only: sensorData1.last_run_only,
				logging_interval: sensorData1.logging_interval,
				communication_interval: parsedData.communication_intervals
					? parsedData.communication_intervals.interval_1.interval_1
						? parsedData.communication_intervals.interval_1.interval_1
						: parsedData.communication_intervals.interval_1
					: 120,
				communication_mode: parsedData.communication_mode
					? parsedData.communication_mode
					: moduleData.module.communication_mode_templates[1].index,
				temperature_display_unit: parsedData.temperature_display_unit,
				unit: sensorData1.out_units_id === 4 ? sensorData2.out_units_id : sensorData1.out_units_id,
				currentModuleChannels: {channelsUsed: [], channelsFree: []},
				selectedChannel: selectedChannel,
				moduleData: {
					channel_types: allChannelTypes,
					module: {
						module_id: moduleData.module.module_id,
						channels: moduleData.module.channels,
						used_channels: moduleData.module.used_channels,
					},
				},
			};

			const index = this.state.currentLimitAlarmsFormIndex;

			GetInitialLicenseOfferingResult(
				moduleData.module.module_id?.toString(),
				sensorData1.serial_number,
				index,
				sensorBasicsForm,
				this.state.formStates?.limitAlarmForms?.at(index),
				this.state.sensorIssuesForm
			).then(result => this.setState(prevState => ({...prevState, ...result}), this.getLicenseOfferings));

			that.setState(prevState => ({
				...prevState,
				additionalConfiguration: {
					...prevState.additionalConfiguration,
					has_additional_configuration: true,
					configuration_templates: moduleData.module.configuration_templates,
					configuration_template: new Map<string, Template>(),
					configuration_template_result: parsedData,
					communication_mode_templates: hasConfigurationTemplates ? moduleData.module.communication_mode_templates : null,
					additional_configuration_forms: {},
				},
				currentChannelIndex: 1,
				currentValueIndex: 0,
				formStates: {
					limitAlarmForms: limitAlarmForms,
					predictiveForm: null,
				},
				is_restart: parsedData.multiuse === 'restart',
				sensorBasicsForm: sensorBasicsForm,
				sensorIssuesForm: {
					editable: sensorData1.sensor_issue_alarm_id === null,
					email_notification: sensorData1.sensor_issue_email_notification,
					email_users: sensorData1.recipients_email_list.issue_recipients
						? sensorData1.recipients_email_list.issue_recipients
						: [],
					issue_alarm_creation_type: sensorData1.sensor_issue_alarm_id || 0,
					sms_notification: sensorData1.sensor_issue_sms_notification,
					sms_users: sensorData1.recipients_sms_list.issue_recipients ? sensorData1.recipients_sms_list.issue_recipients : [],
					use_issue_alarms: sensorData1.sensor_issue_alarm_id !== null,
				},
				sensors: sensorData2 ? [sensorData1, sensorData2] : [sensorData1],
				steps: GetSensorSteps(hasConfigurationTemplates, false, false, configTemplateName),

				templateName: configTemplateName,

				uncounted_steps: 2,
			}));
		});
	}

	sortChannels = channels => {
		channels.map(channel => {
			for (let i = 0; i < channel.channel_types.length; i++) {
				if (channel.channel_types[i].symbolValue.includes('humidity')) {
					let tmp = channel.channel_types[i];
					channel.channel_types[i] = channel.channel_types[channel.channel_types.length - 1];
					channel.channel_types[channel.channel_types.length - 1] = tmp;
				} else if (
					channel.channel_types[i].symbolValue.includes('temperature') &&
					!channel.channel_types[i].symbolValue.includes('external')
				) {
					let tmp = channel.channel_types[i];
					channel.channel_types[i] = channel.channel_types[0];
					channel.channel_types[0] = tmp;
				}
			}
		});
	};

	getIssueProfiles() {
		let that = this;

		IssueProfileService.issueProfiles({}, RequestLogger.createLogData('edit-libero-gx', 'load-issue-profiles', 'onLoad'))
			.then(function (response) {
				that.setState({issueProfiles: response.data});
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	handleSensorBasicsInputChange = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;

		this.setState(prevState => ({
			sensorBasicsForm: {
				...prevState.sensorBasicsForm,
				[input.name]: value,
			},
		}));
	};

	handleCommunicationIntervalChange = event => {
		let value = event;
		this.setState(prevState => ({
			...prevState,
			sensorBasicsForm: {
				...prevState.sensorBasicsForm,
				communication_interval: value,
			},
		}));
	};

	handleCommunicationModeChange = event => {
		this.setState(prevState => ({
			...prevState,
			sensorBasicsForm: {
				...prevState.sensorBasicsForm,
				communication_mode: event,
			},
		}));
	};

	handleLoggingIntervalChange = event => {
		if (this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)) {
			PrepareLicenseOfferingFromLineItemTypes(
				this.state.moduleForm.serial_number,
				this.state.sensorBasicsForm.selectedChannel.selectedChannel,
				this.state.sensorBasicsForm.selectedChannel.selectedValueIndex,
				this.state.licenseOffering,
				[InternalProductCategories.LoggingIntervalIPC],
				null,
				null,
				null,
				event
			).then(result => {
				this.setState(prevState => ({...prevState, licenseOffering: result}), this.getLicenseOfferings);
			});
		}

		this.setState(
			prevState => ({
				sensorBasicsForm: {
					...prevState.sensorBasicsForm,
					logging_interval: event,
				},
			}),
			this.getLicenseOfferings
		);
	};

	handleUnitChange = event => {
		this.setState(prevState => ({
			...prevState,
			sensorBasicsForm: {
				...prevState.sensorBasicsForm,
				unit: event,
				temperature_display_unit: Shared.getSensorUnit(event).replace(/^°+/g, ''),
			},
			additionalConfiguration: {
				...prevState.additionalConfiguration,
				configuration_template_result: {
					...prevState.additionalConfiguration.configuration_template_result,
				},
			},
		}));
	};

	handleIssueAlarmCreationTypeChange = event => {
		let that = this;

		let profile: IssueProfile = {
			limit_issue_name: '',
			missing_value_alarm: false, //Not used anymore
			missing_value_alarm_delay: 0, //Not used anymore
			lost_measurement_alarm: false,
			missing_communication_warning: false,
			missing_communication_warning_enum: 0,
			light_warning: false,
			tilt_warning: false,
			sensor_failure_alarm: false,
			sensor_failure_alarm_delay: 0,
			radio_connection_warning: false, //Not used anymore https://elproag.atlassian.net/browse/TH-460
			radio_connection_warning_delay: 0, //Not used anymore https://elproag.atlassian.net/browse/TH-460
			radio_connection_warning_threshold: -90, //Not used anymore https://elproag.atlassian.net/browse/TH-460
			low_battery_warning: false,
			editable: true,
		};

		if (event !== 0) {
			that.state.issueProfiles.forEach(function (item) {
				if (item.id === event) {
					profile = item;
					profile.editable = false;
				}
			});
		}

		that.setState(
			prevState => ({
				...prevState,
				sensorIssuesForm: {
					...prevState.sensorIssuesForm,
					issue_alarm_creation_type: event,
					limit_issue_name: profile.name,
					missing_value_alarm: profile.missing_value_alarm, //Not used anymore
					missing_value_alarm_delay: profile.missing_value_alarm_delay, //Not used anymore
					lost_measurement_alarm: profile.lost_measurement_alarm,
					missing_communication_warning: profile.missing_communication_warning,
					missing_communication_warning_enum: profile.missing_communication_warning_enum,
					light_warning: profile.light_warning,
					tilt_warning: profile.tilt_warning,
					sensor_failure_alarm: profile.sensor_failure_alarm,
					sensor_failure_alarm_delay: profile.sensor_failure_alarm_delay,
					radio_connection_warning: profile.radio_connection_warning, //Not used anymore https://elproag.atlassian.net/browse/TH-460
					radio_connection_warning_delay: profile.radio_connection_warning_delay, //Not used anymore https://elproag.atlassian.net/browse/TH-460
					radio_connection_warning_threshold: profile.radio_connection_warning_threshold, //Not used anymore https://elproag.atlassian.net/browse/TH-460
					low_battery_warning: profile.low_battery_warning,
					editable: profile.editable,
				},
			}),
			() => {
				this.setIssueProfileLineItems();
			}
		);
	};

	handleIssueAlarmInputChange = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;

		this.setState(
			prevState => ({
				sensorIssuesForm: {
					...prevState.sensorIssuesForm,
					[input.name]: value,
				},
			}),
			() => {
				this.setIssueProfileLineItems();
				input.name === 'sms_notification' ? this.getLicenseOfferings() : undefined;
			}
		);
	};

	makeIssueAlarmsEditable = () => {
		this.setState(prevState => ({
			sensorIssuesForm: {
				...prevState.sensorIssuesForm,
				editable: true,
			},
		}));
	};

	handleUseSensorIssuesChecked = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;
		this.setState(
			prevState => ({
				sensorIssuesForm: {
					...prevState.sensorIssuesForm,
					[input.name]: input.checked,
					editable: value,
				},
			}),
			() => {
				this.setIssueProfileLineItems();
			}
		);
	};

	setIssueProfileLineItems = () => {
		if (this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)) {
			PrepareLicenseOfferingFromLineItemTypes(
				this.state.moduleForm.serial_number,
				this.state.sensorBasicsForm.selectedChannel.selectedChannel,
				this.state.sensorBasicsForm.selectedChannel.selectedValueIndex,
				this.state.licenseOffering,
				[InternalProductCategories.IssueProfileEmailIPC, InternalProductCategories.IssueProfileSmsIPC],
				0,
				null,
				this.state.sensorIssuesForm,
				null
			).then(result => {
				this.setState(prevState => ({...prevState, licenseOffering: result}), this.getLicenseOfferings);
			});
		}
	};

	setOfferingObject = offeringObject => {
		this.setState({offering: offeringObject});
		if (offeringObject.voucher_rest > 0) {
			this.setState({
				summaryForm: {
					...this.state.summaryForm,
					disable_sensor_update_msg:
						'Update is not possible because voucher not fully redeemable (' +
						offeringObject.voucher_rest +
						' CHF remaining)' +
						'. Please use prepaid credit.',
				},
			});
		}
	};

	getOffering = (redeem_prepaid?: boolean) => {
		let that = this;

		this.setState({offering: undefined});

		let payload = {
			logging_interval: this.state.sensorBasicsForm.logging_interval,
			sms_active: SmsAddonRequired(
				this.state.moduleForm.serial_number,
				this.state.sensorBasicsForm.selectedChannel?.selectedChannel,
				this.state.sensorBasicsForm.selectedChannel?.selectedValueIndex,
				this.state.formStates?.limitAlarmForms,
				this.state.sensorIssuesForm
			),
			vouchers: this.state.summaryForm.vouchers,
			redeem_prepaid: redeem_prepaid ? redeem_prepaid : false,
			serial_number: null,
		};

		if (this.state.moduleForm) payload.serial_number = this.state.moduleForm.serial_number;

		BillingService.getOffering(payload, RequestLogger.createLogData('edit-libero-gx', 'get-offering'))
			.then(function (response) {
				that.setOfferingObject(response.data);
			})
			.catch(function (error) {
				console.error(error);
			});
	};

	back = () => {
		this.updateCurrentStep(WizardNavigationUtil.GetNextStep(this.state, 'backward'));

		// set terms of use and disable update warning message to false, when go back 1 step
		this.setState({
			summaryForm: {
				...this.state.summaryForm,
				disable_sensor_update_msg: '',
			},
		});
	};

	next = () => {
		let nextStep = this.state.current_step;

		if (nextStep === 0 && Array.isArray(this.state.additionalConfiguration?.configuration_templates)) {
			const firstTemplateId = this.state.additionalConfiguration.configuration_templates[0].id;
			this.handleConfigurationTemplateForm(firstTemplateId, true);
		}

		this.updateCurrentStep(WizardNavigationUtil.GetNextStep(this.state, 'forward'));
		return null;
	};

	limitAlarmsFormBack = () => {
		if (this.state.currentLimitAlarmsFormIndex === 0) {
			this.back();
		} else {
			this.setState(prevState => ({currentLimitAlarmsFormIndex: prevState.currentLimitAlarmsFormIndex - 1}));
		}
	};

	limitAlarmsFormNext = () => {
		if (
			this.state.currentLimitAlarmsFormIndex ===
			GetChannelTypes(this.state.sensorBasicsForm, DeviceUtils.GetDeviceFamily(this.state.moduleForm.serial_number)).length - 1
		) {
			this.next();
		} else {
			this.setState(prevState => ({currentLimitAlarmsFormIndex: prevState.currentLimitAlarmsFormIndex + 1}));
		}
	};

	getLicenseOfferings = (redeem_prepaid?: boolean) => {
		if (this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)) {
			GetLicenseOffering(
				this.state.sensorBasicsForm.moduleData.module.module_id?.toString(),
				this.state.hasSubscription,
				this.state.licenseOffering
			).then(l => {
				this.setState({licenseOfferingResult: l});
			});
		} else {
			this.getOffering(redeem_prepaid);
		}
	};

	buySensor = async creditCard => {
		const data = await prepareConnectModuleRequest(
			this.state.sensorBasicsForm,
			this.state.moduleForm,
			this.state.formStates,
			this.state.sensorIssuesForm,
			this.state.summaryForm,
			this.state.offering,
			this.context.AuthContext.Organization.Config.global.tiltEnabled,
			this.state.additionalConfiguration,
			null,
			creditCard
		);

		const response = await LegacySensorService.startRun(data, RequestLogger.createLogData('edit-libero-gx', 'start-run', 'onClick'));
		try {
			const startRunMultiResponse = response.data as StartRunMultiResponse;
			SetUpPredictive(
				this.context?.AuthContext?.Organization?.Uuid,
				this.state.formStates?.predictiveForm,
				startRunMultiResponse.result_all[0].status.sensors_id,
				this.state.sensorBasicsForm.logging_interval
			);

			const successForwardUrl =
				'/connectModule/' +
				response.data.status.sensors_id +
				'/' +
				this.state.moduleForm.serial_number +
				'/' +
				this.state.moduleForm.serial_number.substring(0, 2);

			if (this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)) {
				this.state.hasSubscription
					? SetUpSubscriptionUpdate(
							this.state.sensorBasicsForm.moduleData.module.module_id?.toString(),
							this.state.licenseOffering,
							successForwardUrl,
							this.props.history,
							this.context.AccessContext.user.user_settings.devicesViewSettings
					  )
					: SetUpCheckoutSession(
							this.state.sensorBasicsForm.moduleData.module.module_id?.toString(),
							this.state.licenseOffering,
							successForwardUrl,
							this.props.history,
							this.context.AccessContext.user.user_settings.devicesViewSettings
					  );
			} else {
				this.props.history.push({
					pathname: successForwardUrl,
				});
			}
		} catch (error) {
			console.log(error);
			RegistryHelper.logPublishAndToast(error, pubsub.publish.bind(null, 'catchRegistryExceptions'), 'updating');
		}
	};

	buyAndStart = creditCardObject => {
		let priceObject = this.getCurrentPriceObject();
		showLiberoGWarning(priceObject, this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing), async () => {
			await this.buySensor(creditCardObject);
		});
	};

	updateCurrentStep = step => {
		this.setState({current_step: step});
		pubsub.publish('addNewSensorStepChanged', {current_step: step});
	};

	close = e => {
		const DevicesViewSettings = this.context.AccessContext.user.user_settings.devicesViewSettings;
		this.props.history.push(DevicesViewSettings.view);
		e.preventDefault();
	};

	handleTermsOfUse = _ => {
		this.setState({
			summaryForm: {
				...this.state.summaryForm,
				terms_of_use: !this.state.summaryForm.terms_of_use,
			},
		});
	};

	handleChannelChange = value => {
		const channel = parseInt(value.split('-')[0]);
		const valueIndex = parseInt(value.split('-')[1]);

		const selected = this.state.sensorBasicsForm.currentModuleChannels.channelsFree.find(obj => obj.channel_number === channel);
		selected.selectedChannel = channel;
		selected.selectedValueIndex = valueIndex;
		selected.selected = channel + '-' + valueIndex;

		let defaultUnit = this.state.sensorBasicsForm.defaultSettingUnit;
		selected.channel_types.forEach(channel => {
			if (channel.value_index === valueIndex) {
				const hasDefaultUnit = channel.units.find(unit => unit.id === this.state.sensorBasicsForm.defaultSettingUnit);
				if (!hasDefaultUnit) {
					defaultUnit = channel.units[0].id;
				}
			}
		});

		this.setState(prevState => ({
			...prevState,
			sensorBasicsForm: {
				...prevState.sensorBasicsForm,
				unit: defaultUnit,
				selectedChannel: selected,
			},
		}));
	};

	handleConfigurationTemplateForm = (id, skipped = false) => {
		LegacySensorService.configuration_template(
			id,
			RequestLogger.createLogData('edit-libero-gx', 'load-configuration-template', 'onLoad')
		).then(response => {
			const configTemplateName = findFamilyTypeFromTemplateName(response.data.name);
			this.setState(prevState => ({
				additionalConfiguration: {
					...prevState.additionalConfiguration,
					configuration_template: response.data.template,
					configuration_template_result: {
						...prevState.additionalConfiguration.configuration_template_result,
						configuration_templates_id: id,
					},
				},
				templateName: configTemplateName,
				steps: GetSensorSteps(true, response.data.template, true, configTemplateName),
			}));

			if (skipped === false) this.next();
		});
	};

	handleConfigFormChange = value => {
		this.setState(prevState => ({
			additionalConfiguration: {
				...prevState.additionalConfiguration,
				configuration_template_result: {
					...prevState.additionalConfiguration.configuration_template_result,
					...value,
				},
			},
		}));
	};

	handleConfigFormError(section, errors, sectionErrors = {}) {
		const hasErrors = Object.values(errors).some(val => val);

		this.setState(prevState => {
			const prevSectionErrors = prevState.additionalConfiguration.additional_configuration_forms[`${section}_errors`] || {};
			const hasSectionErrors = Object.values({...prevSectionErrors, ...sectionErrors}).some(val => !!val);

			return {
				additionalConfiguration: {
					...prevState.additionalConfiguration,
					additional_configuration_forms: {
						...prevState.additionalConfiguration.additional_configuration_forms,
						[`${section}_is_valid`]: !hasErrors && !hasSectionErrors,
						[`${section}_errors`]: {...prevSectionErrors, ...sectionErrors},
					},
				},
			};
		});
	}

	handleConfigFormSubmit = (e, section) => {
		e.preventDefault();

		const additional_configuration_forms = this.state.additionalConfiguration.additional_configuration_forms;
		if (additional_configuration_forms[`${section}_is_valid`] !== false) {
			this.next();
		} else {
			this.setState(prevState => ({
				additionalConfiguration: {
					...prevState.additionalConfiguration,
					additional_configuration_forms: {
						...prevState.additionalConfiguration.additional_configuration_forms,
						[`${section}_is_submitted`]: true,
					},
				},
			}));
		}
	};

	maxIndexInMap = (objectMap: Map<string, {index: number}>): number => {
		return Math.max(...Object.values(objectMap).map(o => o.index));
	};

	isLastConfigurationTemplateStep = (index: number): boolean => {
		return index === this.maxIndexInMap(this.state.additionalConfiguration.configuration_template);
	};

	getCurrentPriceObject = (): CurrentPriceResult => {
		let currentPriceProps: CurrentPriceProps = {
			totalAmount: this.state.licenseOfferingResult?.totalAmount,
			actualPrice: this.state.offering?.actual_price,
			currency: this.state.licenseOfferingResult?.currency,
			interval: this.state.licenseOfferingResult?.lineItems[0]?.interval,
		};
		return CurrentPriceExtension.getCurrentPrice(currentPriceProps, this.context.FeatureFlagsContext);
	};

	saveLimitAlarmStateCallback = (index: number, limitAlarmFormState: LimitAlarmFormState) => {
		const updateState = ExportFormStateAndSteps(this.state, index, limitAlarmFormState);

		let formStates = this.state.formStates;
		formStates.limitAlarmForms[index] = limitAlarmFormState;

		this.setState(prev => ({...prev, ...updateState, formStates: formStates}));
	};

	onLimitAlarmFormStateChange = (index: number, limitAlarmFormState: LimitAlarmFormState) => {
		if (this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)) {
			PrepareLicenseOfferingFromLineItemTypes(
				this.state.moduleForm.serial_number,
				this.state.sensorBasicsForm.selectedChannel.selectedChannel,
				this.state.sensorBasicsForm.selectedChannel.selectedValueIndex,
				this.state.licenseOffering,
				[InternalProductCategories.LimitProfileEmailIPC, InternalProductCategories.LimitProfileSmsIPC],
				index,
				limitAlarmFormState,
				null,
				null
			).then(result => {
				this.setState(prevState => ({...prevState, licenseOffering: result}), this.getLicenseOfferings);
			});
		}
	};

	calculateCheckSum = async (): Promise<string> => {
		const data = await prepareConnectModuleRequest(
			this.state.sensorBasicsForm,
			this.state.moduleForm,
			this.state.formStates,
			this.state.sensorIssuesForm,
			this.state.summaryForm,
			this.state.offering,
			this.context.AuthContext.Organization.Config.global.tiltEnabled,
			this.state.additionalConfiguration
		);
		return data.additional_configuration.config_id;
	};

	render() {
		return (
			<>
				<ViewHeader heading={'Start New Run'}>
					<Col>
						<Button type="primary" onClick={this.close} size={'large'}>
							<em className="fsInButton elpro-Close fa-fw" />
							<span>
								<Trans i18nKey={'buttons.close'} />
							</span>
						</Button>
					</Col>
				</ViewHeader>
				<ContentWrapper>
					<div style={{paddingRight: '85px'}}>
						{
							<Col>
								{this.state.current_step >= 0 && !this.state.is_restart && (
									<RadialSteps
										current_step={this.state.current_step}
										steps={this.state.steps}
										uncounted_steps={this.state.uncounted_steps || 0}
									/>
								)}
							</Col>
						}

						{this.state.additionalConfiguration.has_additional_configuration &&
							(this.state.current_step === 0 || this.state.current_step === 1) &&
							(this.state.additionalConfiguration.configuration_templates.length > 1 ? (
								<ConfigurationTemplatesForm
									configurationTemplate={
										!!this.state.additionalConfiguration
											? this.state.additionalConfiguration.configuration_template_result['configuration_templates_id']
											: ''
									}
									configurationTemplates={this.state.additionalConfiguration.configuration_templates}
									handleConfigurationTemplateForm={this.handleConfigurationTemplateForm}
									onNext={this.next}
								/>
							) : (
								this.next()
							))}

						{!this.state.is_restart ? (
							<>
								{this.state.additionalConfiguration.has_additional_configuration && this.state.current_step === 2 && (
									<SensorBasicsFormComponent
										lastRunOnly={this.state.sensorBasicsForm.last_run_only}
										sensorBasicsForm={this.state.sensorBasicsForm}
										handleChannelChange={this.handleChannelChange}
										handleSensorBasicsInputChange={this.handleSensorBasicsInputChange}
										handleLoggingIntervalChange={this.handleLoggingIntervalChange}
										handleCommunicationIntervalChange={this.handleCommunicationIntervalChange}
										handleCommunicationModeChange={this.handleCommunicationModeChange}
										handleUnitChange={this.handleUnitChange}
										currentPrice={this.getCurrentPriceObject()}
										onBack={this.state.additionalConfiguration.configuration_templates.length > 1 ? this.back : null}
										onNext={this.next}
										hasConfigurationTemplate={this.state.additionalConfiguration.has_additional_configuration}
										communicationModeTemplates={this.state.additionalConfiguration.communication_mode_templates}
										isReRun={true}
										templateName={this.state.templateName}
										serialNumber={this.state.moduleForm.serial_number}
										sensorBasicsFormSettings={getSensorBasicsFormSettingsForReConfiguration(
											this.state.moduleForm.serial_number
										)}
									/>
								)}

								{this.state.additionalConfiguration.has_additional_configuration &&
									Object.entries(this.state.additionalConfiguration.configuration_template).map(
										([sectionKey, section], i) =>
											this.state.current_step === section.index && (
												<form
													key={i}
													name={`${sectionKey}Form`}
													onSubmit={e => this.handleConfigFormSubmit(e, sectionKey)}
												>
													<FormNavigation loading={this.state.loading} />
													<Row>
														<Col span={24}>
															<LiberoGxWizard
																name={sectionKey}
																schema={this.state.additionalConfiguration.configuration_template}
																value={
																	this.state.additionalConfiguration.configuration_template_result || {}
																}
																formState={
																	this.state.additionalConfiguration.additional_configuration_forms
																}
																coreData={{
																	limitAlarmForms: this.state.formStates.limitAlarmForms,
																	sensorBasicsForm: this.state.sensorBasicsForm,
																}}
																onChange={e => this.handleConfigFormChange(e)}
																onError={(e, msg) => this.handleConfigFormError(sectionKey, e, msg)}
																isLastStep={this.isLastConfigurationTemplateStep(section.index)}
															/>
														</Col>
													</Row>
													<FormNavigation onBack={this.back} loading={this.state.loading} />
												</form>
											)
									)}
								{((!this.state.additionalConfiguration.has_additional_configuration && this.state.current_step === 2) ||
									(this.state.additionalConfiguration.has_additional_configuration && this.state.current_step === 4)) &&
									GetChannelTypes(
										this.state.sensorBasicsForm,
										DeviceUtils.GetDeviceFamily(this.state.moduleForm.serial_number)
									).map(
										(channelType, idx: number) =>
											idx == this.state.currentLimitAlarmsFormIndex && (
												<LimitAlarmsForm
													key={idx}
													channelType={channelType}
													currentPrice={this.getCurrentPriceObject()}
													onBack={this.limitAlarmsFormBack}
													onNext={this.limitAlarmsFormNext}
													interval={this.state.sensorBasicsForm.logging_interval}
													unit={
														channelType.units.length === 1
															? channelType.units[0].id
															: this.state.sensorBasicsForm.unit
													}
													sensorIndex={idx}
													initialState={this.state.formStates?.limitAlarmForms?.at(idx)}
													saveStateCallback={limitAlarmFormState =>
														this.saveLimitAlarmStateCallback(idx, limitAlarmFormState)
													}
													moduleSerialNumber={this.state.moduleForm.serial_number}
													onFormStateChange={limitAlarmFormState =>
														this.onLimitAlarmFormStateChange(idx, limitAlarmFormState)
													}
												/>
											)
									)}

								{this.state.current_step === 5 && (
									<SensorIssuesFormComponent
										sensorBasicsForm={this.state.sensorBasicsForm}
										issueProfiles={this.state.issueProfiles}
										sensorIssuesForm={this.state.sensorIssuesForm}
										makeIssueAlarmsEditable={this.makeIssueAlarmsEditable}
										handleUseSensorIssuesChecked={this.handleUseSensorIssuesChecked}
										handleIssueAlarmInputChange={this.handleIssueAlarmInputChange}
										handleIssueAlarmCreationTypeChange={this.handleIssueAlarmCreationTypeChange}
										currentPrice={this.getCurrentPriceObject()}
										onBack={this.back}
										onNext={this.next}
										templateName={this.state.templateName}
										isLiberoG={this.state.isLiberoG}
										sensorSerialNumber={null}
										sms_checkbox_disabled={null}
									/>
								)}
							</>
						) : null}

						{this.state.steps[this.state.current_step].text === PredictiveTrans.title &&
							!this.state.steps[this.state.current_step].disabled && (
								<PredictiveForm
									initialState={this.state.formStates.predictiveForm}
									loading={false}
									onBack={this.back}
									onNext={this.next}
									outUnit={this.state.sensorBasicsForm.unit}
									saveStateCallback={(predictiveFormState: PredictiveFormState) => {
										this.setState({
											formStates: {
												...this.state.formStates,
												predictiveForm: predictiveFormState,
											},
										});
									}}
								></PredictiveForm>
							)}

						{(this.state.steps[this.state.current_step].text == 'sensorWizard.steps.summary' || this.state.is_restart) && (
							<SensorSummaryFormComponent
								additional_configuration={this.state.additionalConfiguration.configuration_template_result}
								communication_mode_templates={this.state.additionalConfiguration.communication_mode_templates}
								configuration_template={this.state.additionalConfiguration.configuration_template}
								formStates={this.state.formStates}
								getLicenseOffering={this.getLicenseOfferings}
								getOffering={this.getOffering}
								handleTermsOfUse={this.handleTermsOfUse}
								hideBuy={this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing) == false}
								issueProfiles={this.state.issueProfiles}
								licenseOfferingResult={this.state.licenseOfferingResult}
								moduleForm={this.state.moduleForm}
								offering={this.state.offering}
								onBack={!this.state.is_restart ? this.back : null}
								onBuyAndStart={this.buyAndStart}
								sensorBasicsForm={this.state.sensorBasicsForm}
								sensorIssuesForm={this.state.sensorIssuesForm}
								sensorPaymentMethodSettings={getSensorPaymentMethodSettingsForReConfiguration(
									this.state.moduleForm.serial_number
								)}
								sensorSummarySettings={getSensorSummarySettingsForReConfiguration(this.state.moduleForm.serial_number)}
								setOfferingObject={this.setOfferingObject}
								summaryForm={this.state.summaryForm}
								templateName={this.state.templateName}
								calculateChecksum={this.calculateCheckSum}
							/>
						)}
					</div>
				</ContentWrapper>
			</>
		);
	}
}

export default EditLiberoGx;
