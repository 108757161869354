import {AlarmLimitView} from '../Elements';
import {Form, Select, SelectProps} from 'antd';
import React, {useEffect, useState} from 'react';
import {AlarmLimit} from '../../../common/types';
import {useTranslation} from 'react-i18next';
import {OutUnit} from '../../../common/constants/OutUnit';
import {LimitProfileService} from '../../../common/services';

export function AlarmLimitSelect(props: {
	disabled: boolean;
	hideDelay?: boolean;
	hideMultiZone?: boolean;
	initialAlarmLimit: AlarmLimit;
	label?: string;
	loggingInterval?: number;
	onSelectCallback: (alarmLimit: AlarmLimit) => void;
	outUnit: OutUnit;
	tooltip?: string;
	onComponentReady?: () => void;
}): React.JSX.Element {
	const [t] = useTranslation();
	const [alarmLimits, setAlarmLimits] = useState<AlarmLimit[]>([]);
	const alarmLimitOptions: SelectProps['options'] = alarmLimits
		.filter(l => !props.hideMultiZone || (l.LowerLimits[1] == null && l.UpperLimits[1] == null))
		.map(limit => ({label: limit.Name, value: limit.Id}));
	const [alarmLimit, setAlarmLimit] = useState<AlarmLimit>(props.initialAlarmLimit);

	const label = props.label ?? t('settings.limitProfiles.title').toString();

	useEffect(() => {
		LimitProfileService.GetLimitProfiles().then(r => {
			setAlarmLimits(r.sort((p1, p2) => p1.Name.localeCompare(p2.Name)));
			props.onComponentReady && props.onComponentReady();
		});
	}, []);

	// Trigger an update once alarm limits have been loaded in case only an id is provided
	useEffect(() => {
		if (!alarmLimits || alarmLimits.length == 0 || !alarmLimit) {
			return;
		}

		onChange(alarmLimit.Id);
	}, [alarmLimits]);

	function onChange(value: number) {
		const newAlarmLimit = alarmLimits.find(l => l.Id == value);
		setAlarmLimit(newAlarmLimit);
		props.onSelectCallback(newAlarmLimit);
	}

	return (
		<>
			<Form.Item
				initialValue={alarmLimit?.Id}
				label={label}
				name={label}
				tooltip={props.tooltip}
				rules={[{required: !props.disabled}]}
			>
				<Select options={alarmLimitOptions} disabled={props.disabled} onChange={onChange} />
			</Form.Item>

			<AlarmLimitView
				alarmLimit={alarmLimit}
				hideDelay={props.hideDelay}
				loggingInterval={props.loggingInterval}
				outUnit={props.outUnit}
			></AlarmLimitView>
		</>
	);
}
