import React, {Component} from 'react';
import {Col, FormGroup, Input, Label, Row} from 'reactstrap';
import {Trans} from 'react-i18next';
import FormValidator from '../../../../Forms/FormValidator';
import CurrentPrice from '../../../../Common/CurrentPrice';
import {Alert, Checkbox} from 'antd';
import {MODULE_FAMILIES} from '../../../../Shared/Constants/Module';
import {CommunicationModeSelectComponent} from './CommunicationModeSelectComponent';
import {CommunicationIntervalSelectComponent} from './CommunicationIntervalSelectComponent';
import {LoggingIntervalSelectComponent} from './LoggingIntervalSelectComponent';
import {FastCommunicationIntervalSelectComponent} from './FastCommunicationIntervalSelectComponent';
import {ModuleChannelSelectComponent} from './ModuleChannelSelectComponent';
import {SensorBasicsNameInputComponent} from './SensorBasicsNameInputComponent';
import {UnitSelectComponent} from './UnitSelectComponent';
import {SensorBasicsFormProps} from './SensorBasicsFormProps';
import {assignName} from './Helpers/InputNameAssigner';
import {disableChannelSelection} from './Helpers/ChannelSelectionDisabler';
import {FormNavigation} from '../../../../Wizard/Elements';
import {FeatureFlag, FeatureFlagHelper} from '../../../../../common/helpers';
import {SensorBasicsFormState} from './SensorBasicsFormState';

class SensorBasicsFormComponent extends Component<SensorBasicsFormProps, SensorBasicsFormState> {
	constructor(props: Readonly<SensorBasicsFormProps>) {
		super(props);

		this.state = {
			sensorBasicsFormSettings: this.props.sensorBasicsFormSettings,
			loading: false,
			hasConfigurationTemplate: this.props.hasConfigurationTemplate,
			communicationModeTemplates: this.props.communicationModeTemplates,
			canConfigureSensor:
				this.props.isReRun === true ? true : !!this.props.sensorBasicsForm.currentModuleChannels.channelsFree.length,
			isConfiguration: this.props.sensorBasicsForm.isConfiguration,
			featureFlags: [],
		};
	}

	async componentDidMount() {
		this.setState({
			featureFlags: await FeatureFlagHelper.GetFeatureFlags(),
		});
	}

	hasError = (formName, inputName, method) => {
		return (
			this.state[formName] &&
			this.state[formName].errors &&
			this.state[formName].errors[inputName] &&
			this.state[formName].errors[inputName][method]
		);
	};

	back = e => {
		this.props.onBack();
		e.preventDefault();
	};

	onSubmit = e => {
		const form = e.target;
		const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));
		const inputNoSelect = assignName(inputs);
		const {errors, hasError} = FormValidator.bulkValidate(inputNoSelect);

		this.setState({
			[form.name]: {
				...this.state[form.name],
				errors,
				loading: true,
			},
		});

		if (!hasError) {
			this.props.onNext();
		} else {
			this.setState({loading: false});
		}

		e.preventDefault();
	};

	render() {
		return (
			<Col>
				<form name="formSensorBasics" onSubmit={this.onSubmit}>
					<FormNavigation loading={this.state.loading} nextDisabled={this.props.isNextButtonDisabled}></FormNavigation>
					{this.state.hasConfigurationTemplate && this.state.canConfigureSensor ? null : this.state.isConfiguration ? null : this
							.state.canConfigureSensor ? (
						this.props.sensorBasicsForm.isSensorRetired ? (
							<div id="add-sensor-retired-sensor-can-not-be-configured">
								<br />
								<Alert
									description={
										<Trans i18nKey={'sensorWizard.sensorBasicsForm.retired_sensor_can_not_be_configured'}>
											{{serial: this.props.sensorBasicsForm.customer_id} as any}
										</Trans>
									}
									type="warning"
								/>
								<br />
							</div>
						) : (
							<ModuleChannelSelectComponent
								value={this.props.sensorBasicsForm.selectedChannel.selected}
								onChange={this.props.handleChannelChange}
								disabled={disableChannelSelection(this.props.sensorBasicsForm)}
								sensorBasicsForm={this.props.sensorBasicsForm}
							/>
						)
					) : (
						<div id="add-sensor-no-more-free-channels">
							<br />
							<Alert
								description={
									<Trans i18nKey={'sensorWizard.sensorBasicsForm.no_more_channels'}>
										{{serial: this.props.sensorBasicsForm.customer_id} as any}
									</Trans>
								}
								type="warning"
							/>
							<br />
						</div>
					)}
					{((this.state.canConfigureSensor && this.props.hasConfigurationTemplate) ||
						this.state.canConfigureSensor ||
						this.state.isConfiguration) &&
					!this.props.sensorBasicsForm.isSensorRetired ? (
						<>
							{!this.props.hasConfigurationTemplate && (
								<SensorBasicsNameInputComponent
									value={this.props.sensorBasicsForm.sensor_name}
									onChange={this.props.handleSensorBasicsInputChange}
									hasError={this.hasError}
								/>
							)}
							{this.state.sensorBasicsFormSettings.showLoggingInterval && (
								<LoggingIntervalSelectComponent
									value={this.props.sensorBasicsForm.logging_interval}
									onChange={this.props.handleLoggingIntervalChange}
									disabled={this.props.sensorBasicsForm.disableChangeLogging}
									hasConfigurationTemplate={this.props.hasConfigurationTemplate}
									serialNumber={this.props.serialNumber}
								/>
							)}
							{this.state.sensorBasicsFormSettings.showCommunicationMode && (
								<CommunicationModeSelectComponent
									onChange={this.props.handleCommunicationModeChange}
									value={this.props.sensorBasicsForm.communication_mode}
									communicationModeTemplates={this.state.communicationModeTemplates}
								/>
							)}
							{this.state.sensorBasicsFormSettings.showCommunicationInterval && (
								<CommunicationIntervalSelectComponent
									value={this.props.sensorBasicsForm.communication_interval}
									onChange={this.props.handleCommunicationIntervalChange}
								/>
							)}
							{this.state.sensorBasicsFormSettings.showFastCommunicationInterval && (
								<Row>
									<Col>
										<FormGroup>
											<Checkbox
												checked={this.props.sensorBasicsForm.optimized_communication_behavior}
												name="optimized_communication_behavior"
												type="checkbox"
												onChange={e => this.props.handleSensorBasicsInputChange(e)}
											>
												Fast communication with optimized communication behavior (use of shorter interval length in
												case of good connection)
											</Checkbox>
										</FormGroup>
									</Col>
								</Row>
							)}

							{this.state.sensorBasicsFormSettings.showFastCommunicationInterval &&
								this.props.sensorBasicsForm.optimized_communication_behavior && (
									<FastCommunicationIntervalSelectComponent
										value={this.props.sensorBasicsForm.fast_communication_interval}
										onChange={this.props.handleFastCommunicationIntervalChange}
									/>
								)}
							{this.state.sensorBasicsFormSettings.showFastCommunicationInterval &&
								this.props.sensorBasicsForm.optimized_communication_behavior && (
									<Row>
										<Col xl={7}>
											<Alert
												key="fastCommunicationIntervalNotification"
												message=""
												description="Fast communication increases battery consumption!"
												type="info"
												banner
											/>
											<br />
										</Col>
									</Row>
								)}
							{this.state.sensorBasicsFormSettings.showTemperatureUnit && (
								<UnitSelectComponent
									value={this.props.sensorBasicsForm.unit}
									onChange={this.props.handleUnitChange}
									channelTypes={this.props.sensorBasicsForm.selectedChannel.channel_types}
									selectedValueIndex={this.props.sensorBasicsForm.selectedChannel.selectedValueIndex}
									hasConfigurationTemplate={this.props.hasConfigurationTemplate}
								/>
							)}
							{!this.props.hasConfigurationTemplate && (
								<Row>
									<Col xl={3}>
										<FormGroup>
											<Label for="inputCustomerId">Customer's ID (optional)</Label>
											<Input
												type="text"
												id="inputCustomerId"
												name="customer_id"
												value={this.props.sensorBasicsForm.customer_id}
												onChange={this.props.handleSensorBasicsInputChange}
											/>
										</FormGroup>
									</Col>
								</Row>
							)}
							{
								<Row>
									<Col xl={3}>
										<FormGroup>
											<Label for="memo">Memo (optional)</Label>
											<Input
												type="textarea"
												name="memo"
												className="form-control"
												id="memo"
												rows="5"
												value={this.props.sensorBasicsForm.memo}
												onChange={this.props.handleSensorBasicsInputChange}
												invalid={this.hasError('formSensorBasics', 'memo', 'maxchar')}
												data-validate='["maxchar"]'
												data-param="512"
												maxLength="513"
											/>
											{this.hasError('formSensorBasics', 'memo', 'maxchar') && (
												<span id="memoNameMax" className="invalid-feedback">
													Field must be be less than 512 characters
												</span>
											)}
										</FormGroup>
									</Col>
								</Row>
							}
							{!this.props.hasConfigurationTemplate && (
								<Row>
									<Col>
										<h5> A logging interval shorter than 10 minutes increases the yearly license fee. </h5>
									</Col>
								</Row>
							)}
							{
								<Row>
									<Col>
										{(this.state.featureFlags && this.state.featureFlags.includes(FeatureFlag.Licensing) == true) ||
										!(this.props.templateName && this.props.templateName === MODULE_FAMILIES.LIBERO_G) ? (
											<CurrentPrice currentPrice={this.props.currentPrice} />
										) : null}
									</Col>
								</Row>
							}
							{this.props.hasConfigurationTemplate && this.props.templateName !== MODULE_FAMILIES.ECOLOG_PRO_G && (
								<Row>
									<Col>
										<FormGroup>
											<Checkbox
												checked={this.props.lastRunOnly}
												name="last_run_only"
												type="checkbox"
												onChange={e => this.props.handleSensorBasicsInputChange(e)}
											>
												Show only last Run (Quick View Access)
											</Checkbox>
										</FormGroup>
									</Col>
								</Row>
							)}
						</>
					) : null}
					<FormNavigation
						loading={this.state.loading}
						nextDisabled={this.props.isNextButtonDisabled}
						onBack={this.props.onBack}
					></FormNavigation>
				</form>
			</Col>
		);
	}
}

export default SensorBasicsFormComponent;
