import {Button, ButtonProps, Checkbox, Col, Form, Input, InputNumber, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import styles from './LimitProfileForm.module.css';
import {AlarmLimit} from '../../common/types';
import {NamePath} from 'antd/es/form/interface';
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {LimitProfilesAreEqual} from '../../common/types/AlarmLimit';
import {LimitProfileService} from '../../common/services';
import {ErrorAlert, Success} from '../Common/Alerts';
import {useLimitProfileActions} from './useLimitProfileActions';

export function LimitProfileForm(
	props: Readonly<{
		limitProfile: AlarmLimit;
		editing: boolean;
		viewOnly: boolean;
	}>
): React.JSX.Element {
	const [form] = Form.useForm();
	const [t] = useTranslation();
	const {viewAllLimitProfiles} = useLimitProfileActions();

	const [savingInProgress, setSavingInProgress] = useState(false);

	const [limitProfile, setLimitProfile] = useState<AlarmLimit>(props.limitProfile);
	const valuesHaveChanged = !LimitProfilesAreEqual(limitProfile, props.limitProfile);

	useEffect(() => {
		if (!props.editing) {
			updateLimitProfile(props.limitProfile);
		}
	}, [props.editing]);

	useEffect(() => {
		updateLimitProfile(props.limitProfile);
	}, [props.limitProfile]);

	const firstFreeHLimit = limitProfile.UpperLimits.findIndex(l => l == null);
	const highestHLimit = firstFreeHLimit == -1 ? limitProfile.UpperLimits.length - 1 : firstFreeHLimit - 1;
	const firstFreeLLimit = limitProfile.LowerLimits.findIndex(l => l == null);
	const lowestLLimit = firstFreeLLimit == -1 ? limitProfile.LowerLimits.length - 1 : firstFreeLLimit - 1;

	interface LimitProfileRow {
		colLimitClassName?: string;
		type?: 'header' | 'value' | 'spacer';
		rowAdditionalClassName?: string;
		valueZone: React.ReactNode;
		limitLabel?: string;
		delayLabel?: string;
		limitNamePath?: NamePath<AlarmLimit>;
		delayNamePath?: NamePath<AlarmLimit>;
	}

	const upperLimitRows = limitProfile.UpperLimits.filter(l => l != null)
		.map(
			(_, idx): LimitProfileRow => ({
				colLimitClassName: styles.CellLimitH,
				rowAdditionalClassName: styles[`RowH${idx + 1}`],
				valueZone: `H${idx + 1}`,
				limitNamePath: ['UpperLimits', idx],
				delayNamePath: ['UpperLimitDelays', idx],
			})
		)
		.reverse();

	const lowerLimitRows = limitProfile.LowerLimits.filter(l => l != null).map(
		(_, idx): LimitProfileRow => ({
			colLimitClassName: styles.CellLimitL,
			rowAdditionalClassName: styles[`RowL${idx + 1}`],
			valueZone: `L${idx + 1}`,
			limitNamePath: ['LowerLimits', idx],
			delayNamePath: ['LowerLimitDelays', idx],
		})
	);

	let rows: LimitProfileRow[] = [
		{
			type: 'header',
			valueZone: (
				<Row justify={'space-between'}>
					<Col>Zone</Col>
					<Col>
						<AddRemoveButtons
							onAddClick={addUpperLimit}
							onRemoveClick={removeUpperLimit}
							firstFreeLimitIdx={firstFreeHLimit}
							disabled={!props.editing}
						/>
					</Col>
				</Row>
			),
			limitLabel: 'Limit',
			delayLabel: 'Delay',
		},
		...upperLimitRows,
		{
			rowAdditionalClassName: styles.RowG,
			type: 'spacer',
			valueZone: 'G',
			limitLabel: '',
			delayLabel: '',
		},
		...lowerLimitRows,
		{
			type: 'header',
			valueZone: (
				<Row justify={'space-between'}>
					<Col></Col>
					<Col>
						<AddRemoveButtons
							onAddClick={addLowerLimit}
							onRemoveClick={removeLowerLimit}
							firstFreeLimitIdx={firstFreeLLimit}
							disabled={!props.editing}
						/>
					</Col>
				</Row>
			),
			limitLabel: '',
			delayLabel: '',
		},
	];

	function updateLimitProfile(newProfile: AlarmLimit) {
		setLimitProfile(newProfile);
		form.setFieldsValue(newProfile);
	}

	function addUpperLimit() {
		const newProfile = structuredClone(limitProfile);
		newProfile.UpperLimits[firstFreeHLimit] = 0;
		newProfile.UpperLimitDelays[firstFreeHLimit] = 0;
		updateLimitProfile(newProfile);
	}

	function removeUpperLimit() {
		const newProfile = structuredClone(limitProfile);
		newProfile.UpperLimits[highestHLimit] = null;
		newProfile.UpperLimitDelays[highestHLimit] = null;
		updateLimitProfile(newProfile);
	}

	function addLowerLimit() {
		const newProfile = structuredClone(limitProfile);
		newProfile.LowerLimits[firstFreeLLimit] = 0;
		newProfile.LowerLimitDelays[firstFreeLLimit] = 0;
		updateLimitProfile(newProfile);
	}

	function removeLowerLimit() {
		const newProfile = structuredClone(limitProfile);
		newProfile.LowerLimits[lowestLLimit] = null;
		newProfile.LowerLimitDelays[lowestLLimit] = null;
		updateLimitProfile(newProfile);
	}

	function validateLimit(limitNamePath: NamePath<AlarmLimit>): Promise<void> | void {
		const currentProfile = form.getFieldsValue();
		if (limitNamePath.length != 2) throw new Error('Invalid limit name path');
		const [property, limitIndex] = limitNamePath;
		if (typeof limitIndex != 'number') throw new Error('Invalid limit index');
		if (property != 'UpperLimits' && property != 'LowerLimits') throw new Error('Invalid limit property: ' + property);

		if (limitIndex < 0) throw new Error('Invalid limit index: ' + limitIndex);

		if (limitIndex == 0 && currentProfile.UpperLimits[0] <= currentProfile.LowerLimits[0])
			return Promise.reject(new Error('Limit H1 must be higher than Limit L1'));

		if (property == 'UpperLimits' && currentProfile[property][limitIndex] <= currentProfile[property][limitIndex - 1])
			return Promise.reject(new Error(`Limit H${limitIndex} must be greater than limit H${limitIndex - 1}`));
		if (property == 'LowerLimits' && currentProfile[property][limitIndex] >= currentProfile[property][limitIndex - 1])
			return Promise.reject(new Error(`Limit L${limitIndex} must be less than limit L${limitIndex - 1}`));

		return Promise.resolve();
	}

	function onValuesChange(changedValues: Partial<AlarmLimit>, _: AlarmLimit) {
		const newProfile = structuredClone(limitProfile);
		Object.entries(changedValues).forEach(([k, v]) => {
			if (Array.isArray(v)) v.forEach((vv, idx) => (newProfile[k][idx] = vv));
			else newProfile[k] = v;
		});
		if (newProfile.UpperLimits[0] == null) newProfile.UpperLimits[0] = 0;
		if (newProfile.LowerLimits[0] == null) newProfile.LowerLimits[0] = 0;

		updateLimitProfile(newProfile);
	}

	function onFinish(values: AlarmLimit) {
		setSavingInProgress(true);
		const endpoint = values.Id ? LimitProfileService.Update(values) : LimitProfileService.Add(values);
		endpoint
			.then(() => Success.fire({text: 'Success'}).then(() => viewAllLimitProfiles()))
			.catch(e => {
				console.error(e);
				ErrorAlert.fire({text: 'Error'}).then();
			})
			.finally(() => setSavingInProgress(false));
	}

	return (
		<Row>
			<Col span={24} xl={18} xxl={12}>
				<Form
					form={form}
					initialValues={limitProfile}
					layout={'vertical'}
					onValuesChange={onValuesChange}
					disabled={!props.editing}
					onFinish={onFinish}
				>
					<Form.Item<AlarmLimit> hidden={true} name={'Id'}>
						<InputNumber />
					</Form.Item>
					<Form.Item<AlarmLimit> hidden={true} name={'Locked'} valuePropName="checked">
						<Checkbox />
					</Form.Item>
					<Form.Item<AlarmLimit>
						name={'Name'}
						label={'Name'}
						rules={[
							{required: true},
							{
								validator: (_1, value: string, _3) =>
									value?.trim() == value
										? Promise.resolve()
										: Promise.reject(new Error('Name must not start or end with whitespace')),
							},
						]}
					>
						<Input />
					</Form.Item>
					{rows.map((r, idx) => (
						<Row
							className={`${r.type == 'header' ? styles.HeaderRow : styles.ContentRow} ${styles.Row} ${
								r.rowAdditionalClassName
							}`}
							key={idx}
							wrap={false}
						>
							<Col span={8}>{r.valueZone}</Col>

							<Col span={8} className={r.colLimitClassName}>
								{r.type == 'header' || r.type == 'spacer' ? (
									r.limitLabel
								) : (
									<Form.Item<AlarmLimit>
										name={r.limitNamePath}
										rules={[
											{
												required: true,
												message: 'Limit is required',
											},
											{validator: (_1, _2, _3) => validateLimit(r.limitNamePath)},
										]}
									>
										<InputNumber />
									</Form.Item>
								)}
							</Col>

							<Col span={8}>
								{r.type == 'header' || r.type == 'spacer' ? (
									r.delayLabel
								) : (
									<Form.Item<AlarmLimit> name={r.delayNamePath}>
										<InputNumber addonAfter={'Logging Intervals'} min={0} />
									</Form.Item>
								)}
							</Col>
						</Row>
					))}
					{!props.viewOnly && (
						<Form.Item>
							<Button type={'primary'} htmlType={'submit'} loading={savingInProgress} disabled={!valuesHaveChanged}>
								{t('global.save').toString()}
							</Button>
						</Form.Item>
					)}
				</Form>
			</Col>
		</Row>
	);
}

const btnAddProps: ButtonProps = {color: 'primary', size: 'small', icon: <PlusOutlined />, variant: 'solid'};
const btnRemoveProps: ButtonProps = {color: 'danger', size: 'small', icon: <MinusOutlined />, variant: 'solid'};

function AddRemoveButtons(
	props: Readonly<{
		onAddClick: () => void;
		onRemoveClick: () => void;
		firstFreeLimitIdx: number;
		disabled: boolean;
	}>
) {
	return (
		<Form.Item>
			<Button onClick={props.onAddClick} disabled={props.firstFreeLimitIdx == -1 || props.disabled} {...btnAddProps} />
			<Button onClick={props.onRemoveClick} disabled={props.firstFreeLimitIdx == 1 || props.disabled} {...btnRemoveProps} />
		</Form.Item>
	);
}
