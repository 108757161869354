import {Col, Input, Row} from 'antd';
import React from 'react';
import styles from './AlarmLimitView.module.css';
import {colProps} from '../defaults';
import {AlarmLimit} from '../../../common/types';
import {WithUnitString} from '../../../common/util';
import {OutUnit} from '../../../common/constants/OutUnit';
import {AlarmLimitProperty, GetLimitsWithDelayByType} from '../../../common/types/AlarmLimit';
import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';

export function AlarmLimitView(props: {
	alarmLimit: AlarmLimit;
	outUnit: OutUnit;
	hideDelay?: boolean;
	loggingInterval?: number;
}): React.JSX.Element {
	const readOnly: boolean = true;
	const zoneFlex: string | number = '1 1 0';
	const limitFlex: string | number = '1 1 0';

	interface AlarmLimitRow {
		colLimitClassName?: string;
		type?: 'header' | 'value' | 'spacer';
		rowAdditionalClassName?: string;
		valueZone: string;
		valueLimit: string;
		valueDelay: string;
	}

	const rows: AlarmLimitRow[] = [
		{
			type: 'header',
			valueZone: 'Zone',
			valueLimit: 'Limit',
			valueDelay: 'Delay',
		},
	];

	function toAlarmLimitRow(alarmLimitProperty: AlarmLimitProperty): AlarmLimitRow {
		return {
			colLimitClassName: alarmLimitProperty.type.charAt(0) == 'H' ? styles.CellLimitH : styles.CellLimitL,
			rowAdditionalClassName: styles[`Row${alarmLimitProperty.type}`],
			valueZone: alarmLimitProperty.type,
			valueLimit: WithUnitString(alarmLimitProperty.limit, props.outUnit),
			valueDelay: props.loggingInterval
				? DateTimeUtils.formatSecondsAsHumanDuration(alarmLimitProperty.delay * props.loggingInterval)
				: `${alarmLimitProperty.delay}`,
		};
	}

	GetLimitsWithDelayByType(props.alarmLimit, 'H', 'desc').forEach(l => rows.push(toAlarmLimitRow(l)));

	rows.push({
		rowAdditionalClassName: styles.RowG,
		type: 'spacer',
		valueZone: 'G',
		valueLimit: '',
		valueDelay: '',
	});

	GetLimitsWithDelayByType(props.alarmLimit, 'L').forEach(l => rows.push(toAlarmLimitRow(l)));

	return (
		<Col {...colProps}>
			{rows.map(r => (
				<Row
					className={`${r.type == 'header' ? styles.HeaderRow : styles.ContentRow} ${styles.Row} ${r.rowAdditionalClassName}`}
					key={r.valueZone}
				>
					<Col flex={zoneFlex}>{r.valueZone}</Col>

					<Col flex={limitFlex} className={r.colLimitClassName}>
						{r.type == 'header' || r.type == 'spacer' ? r.valueLimit : <Input value={r.valueLimit} readOnly={readOnly} />}
					</Col>
					{!props.hideDelay && (
						<Col flex={limitFlex}>
							{r.type == 'header' || r.type == 'spacer' ? r.valueDelay : <Input value={r.valueDelay} readOnly={readOnly} />}
						</Col>
					)}
				</Row>
			))}
		</Col>
	);
}
